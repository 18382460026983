import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Button, Heading, useEloise } from "typed-component-gallery";
import { DBImage } from "./DbImage";

export const About: React.FC = () => {
  const { theme, logic } = useEloise();
  const [edit, setEdit] = useState(false);
  const [aboutData, setAboutData] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);

  // Static data to fall back on if the DB is empty
  const staticData = {
    heading: "About Us",
    logo: "https://firebasestorage.googleapis.com/v0/b/eloiselife-c5cf6.appspot.com/o/wampum%2FMain%2Fimages%2FlogoFull.jpg?alt=media&token=ce1c7f0a-2231-4a8b-a256-1a8e08f9e356",
    secondImage:
      "https://firebasestorage.googleapis.com/v0/b/eloiselife-c5cf6.appspot.com/o/wampum%2FMain%2Fimages%2FnewTrucks.jpg?alt=media&token=90a33782-e313-4ab8-9c91-e9629ba39e51",
    truckImage:
      "https://firebasestorage.googleapis.com/v0/b/eloiselife-c5cf6.appspot.com/o/wampum%2FMain%2Fimages%2FnewTruckWide.png?alt=media&token=26fb4568-8e07-4d95-b693-1b2bbf4b2239",
    paragraphs: [
      "In 1904, brothers Charles and Francis Davis purchased Wampum Hardware Company, a general hardware and explosive store in Wampum, PA. This rambling rural store was quick to become a household name, as well as a dependable resource for necessities like building materials, barbed wire fencing, and dynamite. What started out as a business run by the two brothers is now a highly regarded force in the explosives industry.",
      "The company has grown with the times. It’s kept up with increasingly stringent safety regulations, and embraced the advent of new technologies. In the 1960s, under the fourth generation leadership of Jerry Davis and Paul Davis, Wampum decided to go beyond selling farm goods and cases of explosives. Looking to the future, the company plunged into the business of providing bulk explosives and complete blasting services to the coal mines and quarries of Western Pennsylvania and eastern Ohio and Indiana.",
      "Today, 90% of Wampum’s business is contract blasting – making it one of the largest blasting companies in North America in terms of shot service. WHC has over 200 employees, many with more than 20 years of company service. Wampum Hardware employs over 90 blasters, licensed in five states. WHC’s fleet of equipment continues to grow with their customer’s needs. Wampum Hardware crews are known for being safety & customer-oriented, the employees and owners have huge hearts and always meet the customer’s service needs, while being capable of overcoming the most demanding blasting challenges.",
      "2024 is Wampum Hardware Co.’s 120th year anniversary and the future is bright, here’s to the first steps toward the next 120!",
    ],
  };

  useEffect(() => {
    const fetchAuthState = async () => {
      const user = await logic.fb.auth.currentUser;
      setEdit(!!user);
    };
    fetchAuthState();
  }, [logic]);

  useEffect(() => {
    const fetchAboutData = async () => {
      const data = await logic.fb.docs.getBaseDoc(
        "siteData/wampum/pages/about"
      );
      if (data?.data) {
        setAboutData(data.data);
      } else {
        // Upload static data to Firebase if the database is empty
        await logic.fb.docs.setBaseDoc(
          "siteData/wampum/pages/about",
          staticData
        );
        setAboutData(staticData);
      }
    };
    fetchAboutData();
  }, [logic]);

  const saveChanges = async () => {
    try {
      await logic.fb.docs.setBaseDoc("siteData/wampum/pages/about", aboutData);
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleFieldChange = (field: string, value: string) => {
    setAboutData((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  if (!aboutData) {
    return <div>Loading...</div>;
  }

  return (
    <Container
      fluid
      className="largeMargin">
      <Row>
        {/* Right Column: Text */}
        <Col
          lg={8}
          xs={10}
          className="triangle-corner-col-opp px-5 pb-5"
          style={{
            borderLeft: `1px solid ${theme.colors[0]}`,
            borderBottom: `1px solid ${theme.colors[0]}`,
          }}>
          <Row className="mb-4">
            <Col
              lg={6}
              xs={12}
              className="mx-auto text-center">
              <Heading size={4}>
                {isEditing && edit ? (
                  <Form.Control
                    type="text"
                    value={aboutData.heading}
                    onChange={(e) =>
                      handleFieldChange("heading", e.target.value)
                    }
                  />
                ) : (
                  aboutData.heading
                )}
              </Heading>
            </Col>
          </Row>
          {aboutData.paragraphs.map((paragraph: string, index: number) => (
            <p key={index}>
              {isEditing && edit ? (
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={paragraph}
                  onChange={(e) =>
                    setAboutData((prev: any) => {
                      const updatedParagraphs = [...prev.paragraphs];
                      updatedParagraphs[index] = e.target.value;
                      return { ...prev, paragraphs: updatedParagraphs };
                    })
                  }
                />
              ) : (
                paragraph
              )}
            </p>
          ))}
          <DBImage
            edit={edit}
            style={{ width: "100%" }}
            path="siteData/wampum/pages/about/main/truckImage"
            init={{
              image: aboutData.truckImage,
              altText: "New Truck",
            }}
          />
        </Col>{" "}
        {/* Left Column: Images */}
        <Col
          lg={4}
          xs={10}
          className="mx-auto">
          <Row className="mb-4">
            <Col
              lg={12}
              className="mx-auto text-center">
              <DBImage
                edit={edit}
                style={{ width: "75%" }}
                path="siteData/wampum/pages/about/main/logo"
                init={{
                  image: aboutData.logo,
                  altText: "Company Logo",
                }}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col
              lg={12}
              className="mx-auto text-center">
              <DBImage
                edit={edit}
                style={{ width: "100%" }}
                path="siteData/wampum/pages/about/main/secondImage"
                init={{
                  image: aboutData.secondImage,
                  altText: "Bulk Trucks",
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Edit Controls */}
      {edit && (
        <Row className="mt-4">
          <Col className="text-center">
            {isEditing ? (
              <>
                <Button
                  onClick={saveChanges}
                  className="me-2">
                  Save Changes
                </Button>
                <Button
                  color={1}
                  onClick={() => setIsEditing(false)}>
                  Cancel
                </Button>
              </>
            ) : (
              <Button onClick={() => setIsEditing(true)}>Edit</Button>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};
