import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";

interface PodcastProps {
  edit?: boolean;
  podcasts: { src: string; show: boolean }[];
  onPodcastsChange: (updatedPodcasts: { src: string; show: boolean }[]) => void;
}

export const Podcast: React.FC<PodcastProps> = ({
  edit,
  podcasts,
  onPodcastsChange,
}) => {
  const { theme } = useEloise();

  console.log(podcasts);

  const handleSrcChange = (index: number, newSrc: string) => {
    const updatedPodcasts = podcasts.map((podcast, i) =>
      i === index ? { ...podcast, src: newSrc } : podcast
    );
    onPodcastsChange(updatedPodcasts);
  };

  const toggleVisibility = (index: number) => {
    const updatedPodcasts = podcasts.map((podcast, i) =>
      i === index ? { ...podcast, show: !podcast.show } : podcast
    );
    onPodcastsChange(updatedPodcasts);
  };

  return (
    <>
      {podcasts.map((podcast, index) => (
        <Row
          key={index}
          className="my-5">
          <Col
            lg={8}
            className="mx-auto text-center">
            {podcast.show ? (
              <>
                {edit && (
                  <Form.Control
                    type="text"
                    value={podcast.src}
                    onChange={(e) => handleSrcChange(index, e.target.value)}
                    className="mb-3"
                  />
                )}
                <iframe
                  id={`embedPlayer-${index}`}
                  src={podcast.src}
                  height="175px"
                  sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
                  allow="autoplay *; encrypted-media *; clipboard-write"
                  style={{
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: "10px",
                    transform: "translateZ(0px)",
                    backgroundColor: theme.colors[0],
                  }}></iframe>
              </>
            ) : (
              <p>Podcast {index + 1} is hidden.</p>
            )}
          </Col>
          {edit && (
            <Col
              lg={8}
              className="mx-auto text-center">
              <Form.Check
                type="checkbox"
                id={`toggle-visibility-${index}`}
                label={`Show Podcast ${index + 1}`}
                checked={podcast.show}
                onChange={() => toggleVisibility(index)}
                className="mb-3"
              />
            </Col>
          )}
        </Row>
      ))}
    </>
  );
};
