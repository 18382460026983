import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import {
  Button as BootstrapButton,
  Col,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useEloise } from "typed-component-gallery";

interface VideoData {
  vid: string;
  altText: string;
}

export const DBVideo: React.FC<{
  path: string;
  className?: string;
  init?: VideoData;
  style?: { [key: string]: any };
  edit?: boolean;
  onDelete?: () => void; // Callback for delete action
}> = ({ path, init, style, edit, className, onDelete }) => {
  const [data, setData] = useState<VideoData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [tempData, setTempData] = useState<VideoData | null>(null);
  const [videoOptions, setVideoOptions] = useState<any[]>([]);
  const [isFetchingVideos, setIsFetchingVideos] = useState(false);
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);

  const { logic, theme } = useEloise();

  logic.hooks.useAsyncEffect(async () => {
    try {
      const fetchData = await logic.fb.docs.getBaseDoc(path);

      if (fetchData?.data) {
        setData(fetchData.data);
      } else if (init) {
        await logic.fb.docs.setBaseDoc(path, init);
        setData(init);
      }
    } catch (err) {
      setError("Failed to load video data.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleOpenModal = () => {
    setTempData(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setTempData(null);
    setShowModal(false);
  };

  const handleSave = async () => {
    if (tempData) {
      try {
        await logic.fb.docs.setBaseDoc(path, tempData);
        setData(tempData);
        setShowModal(false);
      } catch (err) {
        console.error("Failed to save changes:", err);
      }
    }
  };

  const fetchVideoOptions = async () => {
    try {
      setIsFetchingVideos(true);
      const fetchedVideos = await logic.fb.storageMethods.getStorageFolder(
        "videos"
      );

      const videoExtensions = [".mp4", ".mov", ".avi", ".webm", ".mkv"];
      const filteredVideos = fetchedVideos.filter((file: any) =>
        videoExtensions.some((ext) => file.name.toLowerCase().endsWith(ext))
      );

      setVideoOptions(filteredVideos);
    } catch (err) {
      console.error("Failed to fetch video options:", err);
    } finally {
      setIsFetchingVideos(false);
    }
  };

  const handleVideoSelect = (videoUrl: string) => {
    setTempData((prev) => ({
      ...prev!,
      vid: videoUrl,
    }));
  };

  const handleVideoUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setIsUploadingVideo(true);
    try {
      const uploadedFile = await logic.fb.storageMethods.uploadFile(
        `videos/${file.name}`,
        file
      );

      if (uploadedFile && typeof uploadedFile !== "boolean") {
        setTempData((prev) => ({
          ...prev!,
          vid: uploadedFile.downloadURL,
        }));
      } else {
        console.error("Video upload failed or returned a boolean.");
      }
    } catch (err) {
      console.error("Failed to upload video:", err);
    } finally {
      setIsUploadingVideo(false);
    }
  };

  if (loading) return <span>Loading...</span>;
  if (error) return <span>{error}</span>;

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {edit && (
        <div
          style={{
            position: "absolute",
            top: "-20px",
            right: "-25px",
            cursor: "pointer",
            zIndex: 10,
          }}
          onClick={handleOpenModal}>
          <Icon
            path={mdiPencil}
            size={1}
          />
        </div>
      )}

      <video
        className={className ?? ""}
        controls
        src={data?.vid}
        style={style}
      />

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Alt Text</Form.Label>
              <Form.Control
                type="text"
                value={tempData?.altText || ""}
                onChange={(e) =>
                  setTempData((prev) => ({
                    ...prev!,
                    altText: e.target.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Video</Form.Label>
              {!videoOptions.length ? (
                <div>
                  <video
                    src={tempData?.vid}
                    style={{
                      display: "block",
                      maxWidth: "100%",
                      marginBottom: "1rem",
                    }}
                    controls
                  />
                  <BootstrapButton
                    variant="primary"
                    onClick={fetchVideoOptions}
                    disabled={isFetchingVideos}>
                    {isFetchingVideos ? "Loading..." : "Replace from Library"}
                  </BootstrapButton>
                  <Form.Control
                    type="file"
                    accept="video/*"
                    onChange={handleVideoUpload}
                    style={{ marginTop: "1rem" }}
                  />
                </div>
              ) : (
                <Row>
                  {videoOptions.map((video, index) => (
                    <Col
                      key={index}
                      xs={4}
                      className="text-center my-2">
                      <video
                        src={video.downloadURL}
                        style={{
                          width: "100%",
                          border:
                            tempData?.vid === video.downloadURL
                              ? `2px solid ${theme.colors[1]}`
                              : "2px solid transparent",
                          cursor: "pointer",
                        }}
                        controls
                        onClick={() => handleVideoSelect(video.downloadURL)}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton
            variant="secondary"
            onClick={handleCloseModal}>
            Cancel
          </BootstrapButton>
          <BootstrapButton
            variant="primary"
            onClick={handleSave}>
            Save Changes
          </BootstrapButton>
          {onDelete && (
            <BootstrapButton
              variant="danger"
              onClick={() => {
                onDelete();
                setShowModal(false);
              }}>
              Delete
            </BootstrapButton>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};
