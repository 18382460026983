import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { Heading, useEloise } from "typed-component-gallery";

export const Careers: React.FC<{ edit?: boolean }> = ({ edit }) => {
  const { theme, logic } = useEloise();
  const [careersData, setCareersData] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [user] = useAuthState(logic.fb.auth);

  const docPath = "siteData/wampum/main/careers";

  useEffect(() => {
    const fetchCareersData = async () => {
      const data = await logic.fb.docs.getBaseDoc(docPath);
      setCareersData(data?.data || {});
    };
    fetchCareersData();
  }, [logic]);

  const saveChanges = async () => {
    try {
      await logic.fb.docs.setBaseDoc(docPath, careersData);
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleFieldChange = (path: Array<string | number>, value: any) => {
    setCareersData((prev: any) => {
      let updated = { ...prev };
      let ref = updated;
      path.forEach((key, index) => {
        if (index === path.length - 1) {
          ref[key] = value;
        } else {
          ref = ref[key];
        }
      });
      return updated;
    });
  };

  if (!careersData) return <div>Loading...</div>;

  return (
    <Container
      fluid
      className="largeMargin">
      {/* Heading Section */}
      <Row>
        <Col
          lg={5}
          xs={11}
          className="mx-auto"
          style={{ borderBottom: "2px solid black" }}>
          <Heading size={4}>
            {isEditing && edit ? (
              <Form.Control
                type="text"
                value={careersData.heading}
                onChange={(e) => handleFieldChange(["heading"], e.target.value)}
              />
            ) : (
              careersData.heading
            )}
          </Heading>
        </Col>
      </Row>

      {/* Intro Section */}
      <Row className="my-5">
        <Col
          lg={5}
          xs={11}
          className="mx-auto">
          <Heading size={4}>
            {isEditing && edit ? (
              <Form.Control
                type="text"
                value={careersData.intro.title}
                onChange={(e) =>
                  handleFieldChange(["intro", "title"], e.target.value)
                }
              />
            ) : (
              careersData.intro.title
            )}
          </Heading>

          {careersData.intro.paragraphs?.map(
            (paragraph: any, index: number) => (
              <p key={index}>
                {isEditing && edit ? (
                  <Form.Control
                    as="textarea"
                    value={paragraph.text}
                    onChange={(e) =>
                      handleFieldChange(
                        ["intro", "paragraphs", index, "text"],
                        e.target.value
                      )
                    }
                  />
                ) : (
                  paragraph.text
                )}
              </p>
            )
          )}

          <Row>
            <Col
              lg={6}
              className="text-center">
              <img
                src={careersData.intro.image}
                alt="Career Intro"
                style={{ width: "100%" }}
              />
            </Col>
            <Col
              lg={6}
              className="text-center">
              <iframe
                src={careersData.intro.video}
                title="Career Video"
                style={{ width: "100%", height: "300px" }}
              />
            </Col>
          </Row>
        </Col>

        {/* Benefits Section with Accordion */}
        <Col
          lg={6}
          xs={11}
          className="mx-auto">
          <Heading size={4}>
            {isEditing && edit ? (
              <Form.Control
                type="text"
                value={careersData.benefits.title}
                onChange={(e) =>
                  handleFieldChange(["benefits", "title"], e.target.value)
                }
              />
            ) : (
              careersData.benefits.title
            )}
          </Heading>
          <Accordion
            defaultActiveKey="0"
            alwaysOpen>
            {careersData.benefits.items?.map((benefit: any, index: number) => (
              <Accordion.Item
                eventKey={index.toString()}
                key={index}>
                <Accordion.Header>
                  {isEditing && edit ? (
                    <Form.Control
                      type="text"
                      value={benefit.title}
                      onChange={(e) =>
                        handleFieldChange(
                          ["benefits", "items", index, "title"],
                          e.target.value
                        )
                      }
                    />
                  ) : (
                    benefit.title
                  )}
                </Accordion.Header>
                <Accordion.Body>
                  {benefit.text && (
                    <div>
                      {isEditing && edit ? (
                        <Form.Control
                          as="textarea"
                          value={benefit.text}
                          onChange={(e) =>
                            handleFieldChange(
                              ["benefits", "items", index, "text"],
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <p>{benefit.text}</p>
                      )}
                    </div>
                  )}
                  {benefit.list && (
                    <ul>
                      {benefit.list.map((subItem: string, subIndex: number) => (
                        <li key={subIndex}>
                          {isEditing && edit ? (
                            <Form.Control
                              type="text"
                              value={subItem}
                              onChange={(e) =>
                                handleFieldChange(
                                  [
                                    "benefits",
                                    "items",
                                    index,
                                    "list",
                                    subIndex,
                                  ],
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            subItem
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>

      {/* NEW Layout for Bottom Section (two columns, three boxes total) */}
      <Row className="my-5">
        {/* Left Column */}
        <Col
          lg={6}
          xs={12}>
          {/* Box #1: CTA / “View Listings” */}
          <div
            style={{
              border: "2px solid black",
              padding: "20px",
              marginBottom: "20px",
            }}>
            <p style={{ fontSize: "1.4rem" }}>
              {isEditing && edit ? (
                <Form.Control
                  as="textarea"
                  value={careersData.bottomCtaIntro || ""}
                  onChange={(e) =>
                    handleFieldChange(["bottomCtaIntro"], e.target.value)
                  }
                />
              ) : (
                // Fallback text if the field is empty:
                careersData.bottomCtaIntro ||
                "If you possess the skills and qualifications we are seeking, we encourage you to apply. For a complete listing of current openings and details in relation to the job, and to apply:"
              )}
            </p>
            {isEditing && edit ? (
              <>
                <Form.Control
                  type="text"
                  className="mb-2"
                  value={careersData.cta?.text || ""}
                  onChange={(e) =>
                    handleFieldChange(["cta", "text"], e.target.value)
                  }
                />
                <Form.Control
                  type="text"
                  value={careersData.cta?.url || ""}
                  onChange={(e) =>
                    handleFieldChange(["cta", "url"], e.target.value)
                  }
                />
              </>
            ) : (
              <Row>
                <Col
                  lg={5}
                  className="mx-auto py-2"
                  style={{ backgroundColor: theme.colors[1] }}>
                  <a
                    style={{
                      color: "white",
                      textDecoration: "none",
                    }}
                    className="text-center "
                    href={careersData.cta?.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    {careersData.cta?.text || "View Listings"}
                  </a>
                </Col>
              </Row>
            )}
          </div>

          {/* Box #2: EEO Link */}
          <div style={{ border: "2px solid black", padding: "20px" }}>
            <p style={{ fontSize: "1.4rem" }}>
              {isEditing && edit ? (
                <Form.Control
                  as="textarea"
                  value={careersData.eeoIntro || ""}
                  onChange={(e) =>
                    handleFieldChange(["eeoIntro"], e.target.value)
                  }
                />
              ) : (
                careersData.eeoIntro ||
                "Wampum Hardware Co. is an equal opportunity employer that upholds the rights and regulations set forth in:"
              )}
            </p>
            {isEditing && edit ? (
              <>
                <Form.Control
                  type="text"
                  className="mb-2"
                  value={careersData.eeoLinkText || ""}
                  onChange={(e) =>
                    handleFieldChange(["eeoLinkText"], e.target.value)
                  }
                />
                <Form.Control
                  type="text"
                  value={careersData.eeoLink || ""}
                  onChange={(e) =>
                    handleFieldChange(["eeoLink"], e.target.value)
                  }
                />
              </>
            ) : (
              <Row>
                <Col
                  lg={5}
                  className="mx-auto py-2"
                  style={{ backgroundColor: theme.colors[1] }}>
                  <a
                    style={{
                      color: "white",
                      textDecoration: "none",
                    }}
                    className="text-center "
                    href={careersData.eeo.button.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    {careersData.eeo.button.text || "View Listings"}
                  </a>
                </Col>
              </Row>
            )}
          </div>
        </Col>

        {/* Right Column */}
        <Col
          lg={6}
          xs={12}>
          {/* Box #3: Disclaimer */}
          <div style={{ border: "2px solid black", padding: "20px" }}>
            {isEditing && edit ? (
              <Form.Control
                as="textarea"
                rows={5}
                value={careersData.eeo?.disclaimer || ""}
                onChange={(e) =>
                  handleFieldChange(["eeo", "disclaimer"], e.target.value)
                }
              />
            ) : (
              <p style={{ fontSize: "1.4rem" }}>
                {careersData.eeo?.disclaimer ||
                  "Wampum Hardware Co. does not discriminate against applicants or employees on the basis of race, color, religion, creed, national origin, ancestry, disability that can be reasonably accommodated without undue hardship, sex, sexual orientation, gender identity, age, citizenship, marital or veteran status, or any other legally protected status."}
              </p>
            )}
          </div>
        </Col>
      </Row>

      {/* Edit Controls */}
      {edit && (
        <Row>
          <Col className="text-center">
            {isEditing ? (
              <>
                <Button
                  onClick={saveChanges}
                  className="me-2">
                  Save Changes
                </Button>
                <Button onClick={() => setIsEditing(false)}>Cancel</Button>
              </>
            ) : (
              <Button onClick={() => setIsEditing(true)}>Edit</Button>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Careers;

export const CareersPage = () => {
  const { logic } = useEloise();
  const [user] = useAuthState(logic.fb.auth);
  if (user) window.location.href = "/admin/careers";
  return <Careers />;
};
