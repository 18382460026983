import React, { useState } from "react";
import styled from "styled-components";

interface SwatchProps {
  color: string;
  onChange: (newColor: string) => void;
}

const Swatch = styled.div<{ color: string }>`
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.color};
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
`;

const ColorInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const hexToHSLA = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0,
    s = 0,
    l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  return `hsla(${(h * 360).toFixed(0)}, ${(s * 100).toFixed(0)}%, ${(
    l * 100
  ).toFixed(0)}%, 1)`;
};

const ColorSwatch: React.FC<SwatchProps> = ({ color, onChange }) => {
  const [hexColor, setHexColor] = useState(color);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    setHexColor(newColor);
    const hsla = hexToHSLA(newColor);
    onChange(hsla);
  };

  return (
    <Swatch color={hexColor}>
      <ColorInput
        type="color"
        value={hexColor}
        onChange={handleChange}
      />
    </Swatch>
  );
};

export default ColorSwatch;
