import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEloise } from "typed-component-gallery";
import "../assets/vid.css";
import { ContactInfo, Footer, History, Welcome } from "../widgets";
import { DBHeading } from "../widgets/DBHeading";
import { DBLinkedImage } from "../widgets/DBLinkedImage";

const Dash: React.FC<{}> = () => {
  const { theme, logic, siteConfig } = useEloise();

  const [user, loading, error] = useAuthState(logic.fb.auth);

  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (user) {
      setEdit(true);
    }
  }, [user]);
  document.addEventListener("scroll", () => {
    const scrollHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollPosition = document.documentElement.scrollTop;
    // Apply the factor here to reduce the effect of the scroll on the movement
    const scrollPercent = (scrollPosition / scrollHeight) * 0.5; // Factor of 0.5 reduces the movement

    const minStartingWidth = 50; // Minimum width percentage of the viewport width
    const maxStartingWidth = 50; // Maximum width percentage of the viewport width
    const dynamicWidthRange = 40;

    // Calculate widths based on modified scroll percent
    const leftTriangleWidth =
      minStartingWidth + dynamicWidthRange * scrollPercent;
    const rightTriangleWidth =
      maxStartingWidth - dynamicWidthRange * scrollPercent;

    const triangleLeft = document.getElementById("triangle-left");
    const triangleRight = document.getElementById("triangle-right");

    if (triangleLeft && triangleRight) {
      triangleLeft.style.width = `calc(${leftTriangleWidth}vw)`;
      triangleRight.style.width = `calc(${rightTriangleWidth}vw)`;
    }
  });

  return (
    <Container
      fluid
      className="mx-auto">
      <Row className="g-0 my-0">
        <div className="fade-in">
          <Welcome edit={edit} />
          <History />

          <Row className="largeMargin">
            <Row>
              <Col
                lg={5}
                xs={10}
                className="mx-auto"
                style={{ borderBottom: theme.border }}>
                <DBHeading
                  edit={edit}
                  size={4}
                  path="siteData/wampum/widgets/dashIntro2"
                  init={{ text: "Partnerships" }}
                />
              </Col>
            </Row>
            <Row className="text-center">
              <Col lg={3}></Col>
              <Col
                lg={3}
                className="mx-auto">
                <DBLinkedImage
                  edit={edit}
                  style={{ width: "95%", margin: "0 auto" }}
                  path="siteData/wampum/widgets/dashImage2"
                  init={{
                    image:
                      "https://firebasestorage.googleapis.com/v0/b/eloiselife-c5cf6.appspot.com/o/wampum%2FMain%2Fimages%2Fdyno.png?alt=media&token=8c5ca566-7db2-4e7f-9e04-20858153d202",
                    altText: "Dyno Nobel Logo",
                    link: "https://www.dynonobel.com/",
                  }}
                />
              </Col>
              <Col
                lg={3}
                className="mx-auto">
                <DBLinkedImage
                  edit={edit}
                  style={{ width: "95%", margin: "0 auto" }}
                  path="siteData/wampum/widgets/dashImage1"
                  init={{
                    image:
                      "https://firebasestorage.googleapis.com/v0/b/eloiselife-c5cf6.appspot.com/o/wampum%2FMain%2Fimages%2Fisee.png?alt=media&token=6f5988f8-9a54-4588-8b82-c90d403c950c",
                    altText: "ISEE Logo",
                    link: "https://isee.org/",
                  }}
                />
              </Col>
              <Col lg={3}></Col>
            </Row>
          </Row>

          <ContactInfo edit={edit} />
          <Footer />
        </div>
      </Row>
    </Container>
  );
};

export default Dash;
