import { signOut } from "@firebase/auth";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router";
import { Button, Heading, useEloise } from "typed-component-gallery";
import { ContactInfo, Timeline } from "../../widgets";

export const Admin: React.FC = () => {
  const pathName = useLocation().pathname;
  const navigate = useNavigate();
  const { logic } = useEloise();
  const [user, loading, error] = useAuthState(logic.fb.auth);
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    console.log(user);
  }, [user]);

  return (
    <Container
      fluid
      style={{ marginTop: "10%" }}>
      <Row className="my-5">
        <Col lg={3}></Col>
        <Col
          lg={6}
          style={{ borderBottom: "3px solid black" }}>
          <Heading size={4}>Admin</Heading>
        </Col>
        <Col lg={2}>
          <Button
            color={1}
            onClick={() => signOut(logic.fb.auth)}>
            Sign Out
          </Button>
        </Col>
      </Row>
      {/* <Row>
        <Col lg={3}>
          <VerticalNav pages={[{ text: "Theme", link: "theme" }]} />
        </Col>
      </Row> */}
      <Row>
        <Col
          lg={10}
          className="mx-auto">
          <ContactInfo edit />
        </Col>
      </Row>
      <Row className="my-4">
        <Col
          lg={6}
          className="mx-auto">
          {" "}
          <Timeline edit />
        </Col>
      </Row>
    </Container>
  );
};
