import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";

export interface ServiceItem {
  id: string;
  title: string;
  subItems: string[];
}

const ServicesList = () => {
  const [services, setServices] = useState<ServiceItem[]>([]);

  const { logic } = useEloise();

  logic.hooks.useAsyncEffect(async () => {
    let fetchCollections = await logic.fb.docs.getBaseCollection(
      "siteData/wampum/services"
    );
    console.log(fetchCollections);

    setServices(
      fetchCollections.map((item) => {
        return { ...item.data, id: item.id };
      })
    );
  }, []);
  // Function to render items and subheaders
  const renderItemOrSubheader = (item: any, index: number) => {
    // Check if the item starts with a dash "-"
    if (item.startsWith("-")) {
      return (
        <div
          key={index}
          className="list-group-subheader">
          {item}
        </div>
      );
    } else if (item.startsWith("_")) {
      // Check if the item starts with an underscore "_"
      // Modify the item to start with a dash instead of an underscore for display
      const modifiedItem = "-" + item.substring(1);
      return (
        // Render the item as more deeply nested. Adjust the className as needed for your styling.
        <div
          key={index}
          className="nested-item my-2">
          {modifiedItem}
        </div>
      );
    } else {
      return (
        <li
          key={index}
          className="my-2">
          {item}
        </li>
      );
    }
  };

  return (
    <Container>
      <Row>
        {services.map((service, index) => (
          <Col
            md={6}
            key={index}>
            <div className="mb-4">
              <h4>{service.title}</h4>

              <ul>
                {service.subItems.map((subItem, subIndex) => (
                  <li key={subIndex}>{subItem}</li>
                ))}
              </ul>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ServicesList;
