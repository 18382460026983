import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEloise } from "typed-component-gallery";
import { Services } from "../widgets";

export const ServicesPage: React.FC = () => {
  const { logic } = useEloise();
  const [user, loading, error] = useAuthState(logic.fb.auth);

  if (user) {
    window.location.href = "/admin/services";
  }
  return (
    <>
      <Services />
    </>
  );
};
