import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";
import { v4 as uuidv4 } from "uuid";
import { GlowingRedOutline, Services } from "../../widgets";
import { ServiceItem } from "../../widgets/servicesList";
export const AdminServicesPage: React.FC = () => {
  const [services, setServices] = useState<ServiceItem[]>([]);

  const { logic } = useEloise();

  logic.hooks.useAsyncEffect(async () => {
    let fetchCollections = await logic.fb.docs.getBaseCollection(
      "siteData/wampum/services"
    );

    setServices(
      fetchCollections.map((item) => {
        return { ...item.data, id: item.id };
      })
    );
  }, []);

  const [isEditing, setIsEditing] = useState(false); // Admin mode toggle
  // Save or update a service to Firebase
  const saveServiceToFirebase = async (service: ServiceItem) => {
    await logic.fb.docs.setBaseDoc(
      `siteData/wampum/services/${service.id}`,
      service
    );
  };

  // Add a new service
  const addService = async () => {
    const newService: ServiceItem = {
      id: uuidv4(), // Generate a unique ID for the service
      title: "New Service",
      subItems: ["New SubItem"],
    };
    setServices((prev) => [...prev, newService]);
    await saveServiceToFirebase(newService);
  };

  // Add a sub-item to a service
  const addSubItem = async (serviceId: string) => {
    setServices((prev) => {
      const updatedServices = prev.map((service) => {
        if (service.id === serviceId) {
          service.subItems.push("New SubItem");
          saveServiceToFirebase(service);
        }
        return service;
      });
      return updatedServices;
    });
  };

  // Edit a service title or sub-item
  const handleEdit = async (
    serviceId: string,
    field: "title" | "subItems",
    subIndex?: number,
    value?: string
  ) => {
    setServices((prev) => {
      const updatedServices = prev.map((service) => {
        if (service.id === serviceId) {
          if (field === "title") {
            service.title = value || "";
          } else if (field === "subItems" && subIndex !== undefined) {
            service.subItems[subIndex] = value || "";
          }
          saveServiceToFirebase(service);
        }
        return service;
      });
      return updatedServices;
    });
  };

  // Delete a service or sub-item
  const handleDelete = async (serviceId: string, subIndex?: number) => {
    setServices((prev) => {
      const updatedServices = prev.filter((service) => {
        if (service.id === serviceId) {
          if (subIndex !== undefined) {
            // Delete a sub-item
            service.subItems = service.subItems.filter(
              (_, i) => i !== subIndex
            );
            saveServiceToFirebase(service);
            return true;
          } else {
            // Delete the entire service
            logic.fb.docs.deleteBaseDoc(
              `siteData/wampum/services/${serviceId}`
            );
            return false;
          }
        }
        return true;
      });
      return updatedServices;
    });
  };
  return (
    <>
      <GlowingRedOutline>
        <Services />
      </GlowingRedOutline>
      <Container>
        <Row className="mb-3">
          <Col>
            <h2>Services List</h2>
            <Button onClick={() => setIsEditing((prev) => !prev)}>
              {isEditing ? "Cancel" : "Edit"}
            </Button>
            {isEditing && (
              <Button
                variant="success"
                onClick={() => {
                  setIsEditing(false);
                }}
                className="ms-2">
                Save
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          {services.map((service) => (
            <Col
              md={6}
              key={service.id}>
              <div className="mb-4">
                {isEditing ? (
                  <Form.Control
                    type="text"
                    value={service.title}
                    onChange={(e) =>
                      handleEdit(service.id, "title", undefined, e.target.value)
                    }
                    className="mb-2"
                  />
                ) : (
                  <h4>{service.title}</h4>
                )}
                <ul>
                  {service.subItems.map((subItem, subIndex) => (
                    <li key={subIndex}>
                      {isEditing ? (
                        <div className="d-flex align-items-center">
                          <Form.Control
                            type="text"
                            value={subItem}
                            onChange={(e) =>
                              handleEdit(
                                service.id,
                                "subItems",
                                subIndex,
                                e.target.value
                              )
                            }
                          />
                          <Button
                            variant="danger"
                            size="sm"
                            className="ms-2"
                            onClick={() => handleDelete(service.id, subIndex)}>
                            Delete
                          </Button>
                        </div>
                      ) : (
                        subItem
                      )}
                    </li>
                  ))}
                </ul>
                {isEditing && (
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => addSubItem(service.id)}>
                    Add SubItem
                  </Button>
                )}
                {isEditing && (
                  <Button
                    variant="danger"
                    size="sm"
                    className="ms-2"
                    onClick={() => handleDelete(service.id)}>
                    Delete Service
                  </Button>
                )}
              </div>
            </Col>
          ))}
          {isEditing && (
            <Col md={12}>
              <Button onClick={addService}>Add Service</Button>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};
