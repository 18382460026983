import { EloisePage } from "typed-component-gallery";
import {
  AboutPage,
  AdminLocations,
  AdminMedia,
  Dash,
  LocationsPage,
  MediaPage,
  Photos,
  Safety,
  ServicesPage,
  ThemeCustomizer,
} from "../components/pages";
import {
  Admin,
  AdminCareers,
  AdminServicesPage,
  Login,
} from "../components/pages/admin";
import { CareersPage } from "../components/pages/careers";

export const Pages: EloisePage[] = [
  {
    name: "/",
    component: <Dash />,
  },
  {
    name: "Home",
    component: <Dash />,
  },
  { name: "About Us", component: <AboutPage />, url: "about-us" },
  {
    name: "Career Opportunities",
    component: <CareersPage />,
    url: "career-opportunities",
  },
  {
    name: "Locations",
    component: <LocationsPage />,
  },
  { name: "Media", component: <MediaPage /> },
  {
    name: "Photos and Videos",
    component: <Photos />,
  },
  {
    name: "Safety",
    component: <Safety />,
  },
  { name: "Services", component: <ServicesPage /> },
  {
    name: "/admin/theme",
    component: <ThemeCustomizer />,
    hidden: true,
    noAuth: false,
  },
  {
    name: "/admin/media",
    component: <AdminMedia />,
    hidden: true,
    noAuth: false,
  },
  {
    name: "/admin/locations",
    component: <AdminLocations />,
    hidden: true,
    noAuth: false,
  },
  {
    name: "/admin/services",
    component: <AdminServicesPage />,
    hidden: true,
    noAuth: false,
  },
  {
    name: "/admin/careers",
    component: <AdminCareers />,
    hidden: true,
    noAuth: false,
  },
  {
    name: "/admin",
    component: <Admin />,
    hidden: true,
    noAuth: false,
  },
  {
    name: "/login",
    component: <Login />,
    hidden: true,
    noAuth: true,
  },
];
