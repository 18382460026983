import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { Button as BootstrapButton, Form, Modal } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";
import { HeadingProps } from "typed-component-gallery/dist/components/blocks/heading";

interface DBHeadingData {
  text: string;
}

interface DBHeadingProps extends Omit<HeadingProps, "children"> {
  path: string;
  init?: DBHeadingData;
  edit?: boolean;
}

export const DBHeading: React.FC<DBHeadingProps> = ({
  path,
  init,
  edit,
  ...props
}) => {
  const { logic, theme } = useEloise();
  const [data, setData] = useState<DBHeadingData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [tempData, setTempData] = useState<DBHeadingData | null>(null);

  logic.hooks.useAsyncEffect(async () => {
    try {
      const fetchData = await logic.fb.docs.getBaseDoc(path);

      if (fetchData?.data) {
        // If data exists in the database, use it
        setData(fetchData.data);
      } else if (init) {
        // If no data in the database, use the init data and save it to the database
        await logic.fb.docs.setBaseDoc(path, init);
        setData(init);
      }
    } catch (err) {
      setError("Failed to load linked image data.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleOpenModal = () => {
    setTempData(data); // Initialize temporary data with current data
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setTempData(null); // Reset temporary data
    setShowModal(false);
  };

  const handleSave = async () => {
    if (tempData) {
      try {
        await logic.fb.docs.setBaseDoc(path, tempData);
        setData(tempData);
        setShowModal(false);
      } catch (err) {
        console.error("Failed to save changes:", err);
      }
    }
  };

  if (loading) return <span>Loading...</span>;
  if (error) return <span>{error}</span>;

  return (
    <div
      style={{ position: "relative", display: "inline-block", width: "100%" }}>
      {/* Edit Icon */}
      {edit && (
        <div
          style={{
            position: "absolute",
            top: "-20px",
            right: "-25px",
            cursor: "pointer",
            zIndex: 10,
          }}
          onClick={handleOpenModal}>
          <Icon
            path={mdiPencil}
            size={1}
          />
        </div>
      )}

      {/* Heading */}
      <Heading {...props}>{data?.text ?? "Placeholder"}</Heading>

      {/* Modal for Editing */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Heading Text</Form.Label>
              <Form.Control
                type="text"
                value={tempData?.text || ""}
                onChange={(e) =>
                  setTempData((prev) => ({
                    ...prev!,
                    text: e.target.value,
                  }))
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton
            variant="secondary"
            onClick={handleCloseModal}>
            Cancel
          </BootstrapButton>
          <BootstrapButton
            variant="primary"
            onClick={handleSave}>
            Save Changes
          </BootstrapButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
