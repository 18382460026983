import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { Heading, useEloise } from "typed-component-gallery";
import { DBImage } from "../widgets/DbImage";
import { DBVideo } from "../widgets/DbVid";

export const Photos: React.FC = () => {
  const { logic, theme } = useEloise();
  const [user, loading, error] = useAuthState(logic.fb.auth);
  const [edit, setEdit] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const [videos, setVideos] = useState<any[]>([]);

  const imageBasePath = "siteData/wampum/photoGallery/images/main/";
  const videoBasePath = "siteData/wampum/photoGallery/videos/main/";

  useEffect(() => {
    if (user) {
      setEdit(true);
    }
  }, [user]);

  // Fetch images and videos from the database
  logic.hooks.useAsyncEffect(async () => {
    try {
      const fetchedImages = await logic.fb.docs.getBaseCollection(
        imageBasePath
      );
      const fetchedVideos = await logic.fb.docs.getBaseCollection(
        videoBasePath
      );
      console.log(fetchedImages, fetchedVideos);

      setImages(
        fetchedImages.map((doc) => ({
          id: doc.id,
          ...doc.data,
        }))
      );

      setVideos(
        fetchedVideos.map((doc) => ({
          id: doc.id,
          ...doc.data,
        }))
      );
    } catch (err) {
      console.error("Error fetching images or videos:", err);
    }
  }, []);

  const addNewPhoto = async () => {
    const newPhotoId = `newPhoto${Date.now()}.png`;
    const newPhoto = {
      id: newPhotoId,
      image: `https://firebasestorage.googleapis.com/v0/b/eloiselife-c5cf6.appspot.com/o/wampum%2FMain%2Fimages%2FbulkTrucks2.png?alt=media&token=65ebdf2c-a005-470f-837e-28f500319f6a`,
      altText: "New Photo",
    };

    try {
      await logic.fb.docs.setBaseDoc(`${imageBasePath}${newPhotoId}`, newPhoto);
      setImages((prevImages) => [...prevImages, newPhoto]);
    } catch (err) {
      console.error("Error adding new photo:", err);
    }
  };

  const addNewVideo = async () => {
    const newVideoId = `newVideo${Date.now()}.mp4`;
    const newVideo = {
      id: newVideoId,
      vid: `https://firebasestorage.googleapis.com/v0/b/eloiselife-c5cf6.appspot.com/o/wampum%2FMain%2Fimages%2FblastVid21.mp4?alt=media&token=65ebdf2c-a005-470f-837e-28f500319f6a`,
      altText: "New Video",
    };

    try {
      await logic.fb.docs.setBaseDoc(`${videoBasePath}${newVideoId}`, newVideo);
      setVideos((prevVideos) => [...prevVideos, newVideo]);
    } catch (err) {
      console.error("Error adding new video:", err);
    }
  };

  const deleteItem = async (id: string, type: "image" | "video") => {
    try {
      const path =
        type === "image" ? `${imageBasePath}${id}` : `${videoBasePath}${id}`;
      await logic.fb.docs.deleteBaseDoc(path);

      if (type === "image") {
        setImages((prevImages) =>
          prevImages.filter((image) => image.id !== id)
        );
      } else {
        setVideos((prevVideos) =>
          prevVideos.filter((video) => video.id !== id)
        );
      }
    } catch (err) {
      console.error("Error deleting item:", err);
    }
  };

  return (
    <Container
      fluid
      className="largeMargin">
      <Row className="my-4">
        <Col
          lg={5}
          className="mx-auto"
          style={{ borderBottom: `2px solid ${theme.colors[0]}` }}>
          <Heading size={4}>Photo Gallery</Heading>
        </Col>
      </Row>

      <Row>
        <Col
          lg={10}
          className="mx-auto">
          <Row>
            {/* Images */}
            {images.map((image) => (
              <Col
                lg={4}
                className="verticalSmall"
                key={image.id}>
                <DBImage
                  className="galleryImage"
                  edit={edit}
                  style={{ width: "100%", borderRadius: "8px" }}
                  path={`${imageBasePath}${image.id}`}
                  init={{
                    image: image.image,
                    altText: image.altText,
                  }}
                  onDelete={() => deleteItem(image.id, "image")}
                />
              </Col>
            ))}

            {/* Videos */}
            {videos.map((video) => (
              <Col
                lg={4}
                className="verticalSmall"
                key={video.id}>
                <DBVideo
                  className="galleryImage"
                  edit={edit}
                  path={`${videoBasePath}${video.id}`}
                  init={{
                    vid: video.vid,
                    altText: video.altText,
                  }}
                  onDelete={() => deleteItem(video.id, "video")}
                />
              </Col>
            ))}

            {/* Add New Buttons */}
            {edit && (
              <Col
                lg={4}
                className="verticalSmall text-center">
                <Row className="my-4">
                  <Button
                    variant="primary"
                    className="mb-3"
                    onClick={addNewPhoto}>
                    Add New Photo
                  </Button>
                </Row>
                <Row className="my-4">
                  <Button
                    variant="secondary"
                    onClick={addNewVideo}>
                    Add New Video
                  </Button>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
