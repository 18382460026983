import { mdiHardHat } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import {
  Button as BootstrapButton,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { Heading, useEloise } from "typed-component-gallery";
import { DBHeading } from "../widgets/DBHeading";
import { DBLinkedImage } from "../widgets/DBLinkedImage"; // Using DBLinkedImage instead of DBImage
import InformationAccordion from "../widgets/safteyAcc";

export const Safety: React.FC = () => {
  const { theme, logic } = useEloise();
  const [edit, setEdit] = useState(false);
  const [safetyPeople, setSafetyPeople] = useState<any[]>([]);
  const [safetyLogos, setSafetyLogos] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [user] = useAuthState(logic.fb.auth);

  const imageBasePath = "siteData/wampum/safety/";
  const personnelBasePath = "siteData/wampum/safteyPeople/";

  // Fetch logos from database
  logic.hooks.useAsyncEffect(async () => {
    try {
      const fetchedImages = await logic.fb.docs.getBaseCollection(
        imageBasePath
      );
      setSafetyLogos(
        fetchedImages
          .filter((item: any) => item.id !== "safetyTitle")
          .map((doc) => ({
            id: doc.id,
            ...doc.data,
          }))
      );
    } catch (err) {
      console.error("Error fetching images:", err);
    }
  }, []);

  // Set edit mode based on auth state
  useEffect(() => {
    setEdit(!!user);
  }, [user]);

  // Fetch safety personnel data
  logic.hooks.useAsyncEffect(async () => {
    const peopleCollection = await logic.fb.docs.getBaseCollection(
      "siteData/wampum/safteyPeople"
    );
    setSafetyPeople(
      peopleCollection.map((item) => ({
        ...item.data,
        id: item.id,
      }))
    );
  }, []);

  const addNewLogo = async () => {
    const newLogoId = `newLogo${Date.now()}`;
    const newLogoData = {
      image:
        "https://firebasestorage.googleapis.com/v0/b/eloiselife-c5cf6.appspot.com/o/wampum%2FMain%2Fimages%2FsafetyLogoPlaceholder.png?alt=media&token=placeholder",
      link: "",
      altText: "New Safety Logo",
    };

    try {
      await logic.fb.docs.setBaseDoc(
        `${imageBasePath}${newLogoId}`,
        newLogoData
      );
      setSafetyLogos((prev) => [...prev, { id: newLogoId, ...newLogoData }]);
    } catch (err) {
      console.error("Error adding new logo:", err);
    }
  };

  const deleteLogo = async (id: string) => {
    try {
      await logic.fb.docs.deleteBaseDoc(`${imageBasePath}${id}`);
      setSafetyLogos((prev) => prev.filter((logo) => logo.id !== id));
    } catch (err) {
      console.error("Error deleting logo:", err);
    }
  };
  const deletePerson = async (id: string) => {
    try {
      await logic.fb.docs.deleteBaseDoc(`${personnelBasePath}${id}`);
      setSafetyPeople((prev) => prev.filter((person) => person.id !== id));
    } catch (err) {
      console.error("Error deleting person:", err);
    }
  };

  const saveChanges = async () => {
    try {
      for (const person of safetyPeople) {
        await logic.fb.docs.setBaseDoc(
          `${personnelBasePath}${person.id}`,
          person
        );
      }
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving safety people changes:", error);
    }
  };

  const addNewPerson = async () => {
    const newPersonId = `person${Date.now()}`;
    const newPersonData = {
      name: "New Name",
      title: "New Title",
      email: "new.email@example.com",
    };

    try {
      await logic.fb.docs.setBaseDoc(
        `${personnelBasePath}${newPersonId}`,
        newPersonData
      );
      setSafetyPeople((prev) => [
        ...prev,
        { id: newPersonId, ...newPersonData },
      ]);
    } catch (err) {
      console.error("Error adding new person:", err);
    }
  };

  return (
    <Container
      fluid
      className="largeMargin">
      {/* Heading Section */}
      <Row className="mt-4">
        <Col
          lg={5}
          xs={10}
          className="mx-auto"
          style={{ borderBottom: "2px solid black" }}>
          <Row>
            <Col lg={3}></Col>
            <Col
              lg={3}
              className="mx-auto text-center">
              <Icon
                path={mdiHardHat}
                size={4}
                color={theme.colors[0]}
              />
            </Col>
            <Col
              lg={3}
              className="mx-auto">
              <DBHeading
                size={4}
                path="siteData/wampum/saftey/safetyTitle"
                edit={edit}
                init={{ text: "Safety" }}
              />
            </Col>
            <Col lg={3}></Col>
          </Row>
        </Col>
      </Row>

      {/* Information Accordion */}
      <Row>
        <Col
          lg={6}
          xs={11}
          className="mx-auto">
          <InformationAccordion edit={edit} />
        </Col>

        {/* Logos Section */}
        <Col
          lg={5}
          className="text-center mt-5">
          <Row>
            <Col
              style={{
                border: `2px solid ${theme.colors[0]}`,
                color: "white",
              }}
              lg={11}
              xs={10}
              className="mx-auto p-5 contact">
              <Row>
                <Col
                  lg={6}
                  className="mx-auto">
                  <Heading
                    align="left"
                    size={3}>
                    Subscribers Of
                  </Heading>
                </Col>
              </Row>
              <Row>
                {safetyLogos.map((logo) => (
                  <Col
                    lg={5}
                    key={logo.id}>
                    <DBLinkedImage
                      edit={edit}
                      style={{ width: 150 }}
                      path={`${imageBasePath}${logo.id}`}
                      init={{
                        link: logo.link || "",
                        image: logo.image,
                        altText: logo.altText,
                      }}
                      onDelete={() => deleteLogo(logo.id)}
                    />
                  </Col>
                ))}
              </Row>
              {edit && (
                <Row>
                  <Col className="text-center mt-3">
                    <BootstrapButton
                      variant="primary"
                      onClick={addNewLogo}>
                      Add New Logo
                    </BootstrapButton>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Safety Personnel */}
      <Row className="my-5">
        <Col
          lg={5}
          xs={8}
          className="mx-auto"
          style={{ borderBottom: theme.border }}>
          <Heading size={4}>Safety Personnel</Heading>
        </Col>
      </Row>

      <Row className="my-5">
        <Col
          lg={10}
          xs={10}
          className="mx-auto p-5"
          style={{
            backgroundColor: theme.colors[0],
            color: "white",
            borderRadius: "5px",
          }}>
          <Row>
            {safetyPeople.map((item: any, index: number) => (
              <Col
                lg={4}
                key={item.id}
                className="mx-auto text-center mb-4">
                {isEditing ? (
                  <>
                    <Form.Control
                      className="mb-2"
                      type="text"
                      value={item.name}
                      onChange={(e) => {
                        const updatedPeople = [...safetyPeople];
                        updatedPeople[index].name = e.target.value;
                        setSafetyPeople(updatedPeople);
                      }}
                    />
                    <Form.Control
                      className="mb-2"
                      type="text"
                      value={item.title}
                      onChange={(e) => {
                        const updatedPeople = [...safetyPeople];
                        updatedPeople[index].title = e.target.value;
                        setSafetyPeople(updatedPeople);
                      }}
                    />
                    <Form.Control
                      className="mb-2"
                      type="email"
                      value={item.email}
                      onChange={(e) => {
                        const updatedPeople = [...safetyPeople];
                        updatedPeople[index].email = e.target.value;
                        setSafetyPeople(updatedPeople);
                      }}
                    />
                    <BootstrapButton
                      variant="danger"
                      onClick={() => deletePerson(item.id)}
                      className="mt-2">
                      Delete
                    </BootstrapButton>
                  </>
                ) : (
                  <>
                    <h2>{item.name}</h2>
                    <p>{item.title}</p>
                    <a
                      style={{ color: "white" }}
                      href={`mailto:${item.email}`}>
                      {item.email}
                    </a>
                  </>
                )}
              </Col>
            ))}
          </Row>
          {edit && (
            <Row>
              <Col className="text-center mt-3">
                <BootstrapButton
                  variant="primary"
                  onClick={addNewPerson}>
                  Add New Person
                </BootstrapButton>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      {/* Edit Controls */}
      {edit && (
        <Row>
          <Col className="text-center">
            {isEditing ? (
              <>
                <BootstrapButton
                  onClick={saveChanges}
                  className="me-2">
                  Save Changes
                </BootstrapButton>
                <BootstrapButton onClick={() => setIsEditing(false)}>
                  Cancel
                </BootstrapButton>
              </>
            ) : (
              <BootstrapButton onClick={() => setIsEditing(true)}>
                Edit
              </BootstrapButton>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Safety;
