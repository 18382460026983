import React, { useState } from "react";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { FileUpload, Heading, useEloise } from "typed-component-gallery";
import { GlowingRedOutline } from "../../widgets";
import { Media } from "../media";

interface StorageItem {
  name: string;
  fullPath: string;
  downloadURL: string;
}

export const AdminMedia: React.FC = () => {
  const { logic } = useEloise();

  const [images, setImages] = useState<StorageItem[]>([]); // Array to store image objects
  const [currentPage, setCurrentPage] = useState(1); // Current page of images
  const [loading, setLoading] = useState(false); // Loading state
  const [selectedMedia, setSelectedMedia] = useState<StorageItem | null>(null); // Selected media for modal
  const [showModal, setShowModal] = useState(false); // Modal state
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [imageMetadata, setImageMetadata] = useState<{
    width: number;
    height: number;
    aspectRatio: string;
  } | null>(null); // Metadata state

  const imagesPerPage = 25; // Number of images per page

  // Fetch images when the component mounts or page changes
  logic.hooks.useAsyncEffect(async () => {
    setLoading(true);
    const storageItems: StorageItem[] =
      await logic.fb.storageMethods.getStorageFolder("images");

    const startIndex = (currentPage - 1) * imagesPerPage;
    const paginatedItems = storageItems.slice(
      startIndex,
      startIndex + imagesPerPage
    );
    setImages(paginatedItems);
    setLoading(false);
  }, [currentPage]);

  // Handle image deletion
  const handleDelete = async (imagePath: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this media? This action cannot be undone."
    );
    if (!confirmDelete) return;

    await logic.fb.storageMethods.deleteFile(imagePath); // Call your delete method
    setImages((prev) => prev.filter((img) => img.fullPath !== imagePath)); // Remove image from state
    if (selectedMedia?.fullPath === imagePath) {
      setShowModal(false); // Close modal if the selected media is deleted
      setSelectedMedia(null);
    }
  };

  // Fetch image metadata (dimensions and aspect ratio)
  const fetchImageMetadata = async (url: string) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      const aspectRatio = (width / height).toFixed(2);
      setImageMetadata({
        width,
        height,
        aspectRatio: `${aspectRatio}:1`,
      });
    };
  };

  const handleMediaClick = (media: StorageItem) => {
    setSelectedMedia(media);
    fetchImageMetadata(media.downloadURL); // Fetch metadata for the clicked image
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMedia(null);
    setImageMetadata(null); // Clear metadata
  };

  const isVideo = (url: string) => {
    const videoExtensions = ["mp4", "webm", "ogg", "mov"];
    let urlList = url.split(".");
    return videoExtensions.includes(urlList[urlList.length - 1]);
  };

  // Callback for file upload result
  const handleFileUpload = (uploadedFile: {
    name: string;
    fullPath: string;
    downloadURL: string;
  }) => {
    // Add the uploaded file to the images array
    setImages((prev) => [...prev, uploadedFile]);
    setShowUploadModal(false); // Close the modal after processing
  };

  return (
    <Container
      fluid
      style={{ marginTop: "10%" }}>
      <Row className="mb-3">
        <GlowingRedOutline>
          <Media />
        </GlowingRedOutline>
      </Row>
      <Row>
        <Col lg={3}></Col>
        <Col lg={6}>
          <Heading size={4}>Media Management</Heading>
        </Col>
        <Col lg={3}>
          {/* Upload Button */}
          <div className="text-center my-4">
            <Button
              variant="primary"
              onClick={() => setShowUploadModal(true)}>
              Upload Media
            </Button>
          </div>
        </Col>
      </Row>

      {loading && (
        <div className="text-center my-4">
          <Spinner
            animation="border"
            role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      <Row>
        {images.map((media, index) => (
          <Col
            key={index}
            xs={12}
            sm={6}
            md={4}
            lg={2}
            className="mb-4">
            <div
              onClick={() => handleMediaClick(media)}
              className="text-center"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "300px",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "8px",
              }}>
              {isVideo(media.name) ? (
                <video
                  controls
                  style={{
                    width: "100%",
                    maxHeight: "150px",
                    objectFit: "cover",
                  }}>
                  <source
                    src={media.downloadURL}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={media.downloadURL}
                  alt={media.name}
                  className="img-fluid rounded"
                  style={{
                    maxHeight: "150px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() => handleMediaClick(media)}
                />
              )}
              <p className="mt-2">{media.name}</p>
              <Button
                variant="danger"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the media click event
                  handleDelete(media.fullPath);
                }}>
                Delete
              </Button>
            </div>
          </Col>
        ))}
      </Row>

      {/* Pagination controls */}
      <div className="d-flex justify-content-center my-5">
        <Button
          variant="secondary"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          className="me-2">
          Previous
        </Button>
        <span className="align-self-center">Page {currentPage}</span>
        <Button
          variant="primary"
          onClick={() => setCurrentPage((prev) => prev + 1)}
          className="ms-2">
          Next
        </Button>
      </div>

      {/* Modal for media preview */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedMedia?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMedia &&
            (isVideo(selectedMedia.name) ? (
              <video
                controls
                style={{
                  width: "100%",
                }}>
                <source
                  src={selectedMedia.downloadURL}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={selectedMedia.downloadURL}
                alt={selectedMedia.name}
                className="img-fluid"
              />
            ))}
          {imageMetadata && (
            <div className="mt-3">
              <p>
                <strong>Width:</strong> {imageMetadata.width}px
              </p>
              <p>
                <strong>Height:</strong> {imageMetadata.height}px
              </p>
              <p>
                <strong>Aspect Ratio:</strong> {imageMetadata.aspectRatio}
              </p>
            </div>
          )}
          {selectedMedia && (
            <Button
              variant="danger"
              size="sm"
              className="mt-3"
              onClick={() => handleDelete(selectedMedia.fullPath)}>
              Delete
            </Button>
          )}
        </Modal.Body>
      </Modal>

      {/* Upload Modal */}
      <Modal
        show={showUploadModal}
        onHide={() => setShowUploadModal(false)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Media</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileUpload
            path="images"
            onFileUpload={handleFileUpload}
            accept={[".jpg", ".jpeg", ".png", ".gif", ".mp4", ".webm", ".ogg"]}
            multiple
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};
