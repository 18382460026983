import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import {
  Button as BootstrapButton,
  Col,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useEloise } from "typed-component-gallery";

interface LinkedImageData {
  link: string;
  image: string;
  altText: string;
}

export const DBLinkedImage: React.FC<{
  path: string;
  init?: LinkedImageData;
  style?: { [key: string]: any };
  edit?: boolean;
  onDelete?: () => void;
}> = ({ path, init, style, edit, onDelete }) => {
  const [data, setData] = useState<LinkedImageData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [tempData, setTempData] = useState<LinkedImageData | null>(null);
  const [imageOptions, setImageOptions] = useState<any[]>([]);
  const [isFetchingImages, setIsFetchingImages] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const { logic, theme } = useEloise();

  logic.hooks.useAsyncEffect(async () => {
    try {
      const fetchData = await logic.fb.docs.getBaseDoc(path);

      if (fetchData?.data) {
        setData(fetchData.data);
      } else if (init) {
        await logic.fb.docs.setBaseDoc(path, init);
        setData(init);
      }
    } catch (err) {
      setError("Failed to load linked image data.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleOpenModal = () => {
    setTempData(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setTempData(null);
    setShowModal(false);
  };

  const handleSave = async () => {
    if (tempData) {
      try {
        await logic.fb.docs.setBaseDoc(path, tempData);
        setData(tempData);
        setShowModal(false);
      } catch (err) {
        console.error("Failed to save changes:", err);
      }
    }
  };

  const fetchImageOptions = async () => {
    try {
      setIsFetchingImages(true);
      const fetchedImages = await logic.fb.storageMethods.getStorageFolder(
        "images"
      );

      const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".webp"];
      const filteredImages = fetchedImages.filter((file: any) =>
        imageExtensions.some((ext) => file.name.toLowerCase().endsWith(ext))
      );

      setImageOptions(filteredImages);
    } catch (err) {
      console.error("Failed to fetch image options:", err);
    } finally {
      setIsFetchingImages(false);
    }
  };

  const handleImageSelect = (imageUrl: string) => {
    setTempData((prev) => ({
      ...prev!,
      image: imageUrl,
    }));
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setIsUploadingImage(true);
    try {
      const uploadedFile = await logic.fb.storageMethods.uploadFile(
        `images/${file.name}`,
        file
      );

      if (uploadedFile && typeof uploadedFile !== "boolean") {
        setTempData((prev) => ({
          ...prev!,
          image: uploadedFile.downloadURL,
        }));
      } else {
        console.error("Image upload failed or returned a boolean.");
      }
    } catch (err) {
      console.error("Failed to upload image:", err);
    } finally {
      setIsUploadingImage(false);
    }
  };

  const handleDelete = async () => {
    if (onDelete) {
      onDelete();
    }
    setShowModal(false);
  };

  if (loading) return <span>Loading...</span>;
  if (error) return <span>{error}</span>;

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {/* Edit Icon */}
      {edit && (
        <div
          style={{
            position: "absolute",
            top: "-20px",
            right: "-25px",
            cursor: "pointer",
            zIndex: 10,
          }}
          onClick={handleOpenModal}>
          <Icon
            color={"black"}
            style={{ color: "black", fill: "black" }}
            path={mdiPencil}
            size={1}
          />
        </div>
      )}

      {/* Image */}
      <a
        href={data?.link}
        target="_blank"
        rel="noopener noreferrer">
        <img
          alt={data?.altText}
          src={data?.image}
          style={style}
        />
      </a>

      {/* Modal for Editing */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Linked Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="url"
                value={tempData?.link || ""}
                onChange={(e) =>
                  setTempData((prev) => ({
                    ...prev!,
                    link: e.target.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Alt Text</Form.Label>
              <Form.Control
                type="text"
                value={tempData?.altText || ""}
                onChange={(e) =>
                  setTempData((prev) => ({
                    ...prev!,
                    altText: e.target.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Image</Form.Label>
              {!imageOptions.length ? (
                <div>
                  <img
                    src={tempData?.image}
                    alt="Current"
                    style={{
                      display: "block",
                      maxWidth: "100%",
                      marginBottom: "1rem",
                    }}
                  />
                  <BootstrapButton
                    variant="primary"
                    onClick={fetchImageOptions}
                    disabled={isFetchingImages}>
                    {isFetchingImages ? "Loading..." : "Replace from Library"}
                  </BootstrapButton>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ marginTop: "1rem" }}
                  />
                </div>
              ) : (
                <Row>
                  {imageOptions.map((image, index) => (
                    <Col
                      key={index}
                      xs={4}
                      className="text-center my-2">
                      <img
                        src={image.downloadURL}
                        alt={`Option ${index + 1}`}
                        style={{
                          width: "100%",
                          border:
                            tempData?.image === image.downloadURL
                              ? `2px solid ${theme.colors[1]}`
                              : "2px solid transparent",
                          cursor: "pointer",
                        }}
                        onClick={() => handleImageSelect(image.downloadURL)}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton
            variant="danger"
            onClick={handleDelete}>
            Delete
          </BootstrapButton>
          <BootstrapButton
            variant="secondary"
            onClick={handleCloseModal}>
            Cancel
          </BootstrapButton>
          <BootstrapButton
            variant="primary"
            onClick={handleSave}>
            Save Changes
          </BootstrapButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
