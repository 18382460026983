export const fb = {
  config: {
    apiKey: "AIzaSyCL7ddsdbD6I4OmB1y1QSs9b-NpNDf_yBo",
    authDomain: "wampum-hardware.firebaseapp.com",
    projectId: "wampum-hardware",
    storageBucket: "wampum-hardware.firebasestorage.app",
    messagingSenderId: "783813032877",
    appId: "1:783813032877:web:511217da877d0069f7ff24",
    measurementId: "G-JY6R2YGQYX",
    databaseURL: "https://wampum-hardware-default-rtdb.firebaseio.com/",
  },
  storageDir: "test",
};
