import React from "react";

const GlowingRedOutline: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <div style={styles.container}>{children}</div>;
};

const styles = {
  container: {
    border: "2px solid red",
    boxShadow: "0 0 10px red",
    padding: "10px", // Optional: Adds spacing between the content and the border
    borderRadius: "8px", // Optional: Rounds the corners
  },
};

export default GlowingRedOutline;
