import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  BorderRadius,
  Button,
  FontSize,
  Heading,
  Input,
  LargeTextInput,
  useEloise,
} from "typed-component-gallery";
import { Color, HSLAColor } from "typed-component-gallery/dist/functions/color";
import ColorSwatch from "../../widgets/colorSwatch";

const Section = styled.div`
  margin-bottom: 2rem;
`;

const ColorList = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const InputGroup = styled.div`
  margin-top: 1rem;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const TextInput = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 1rem;
`;

const SelectInput = styled.select`
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 1rem;
`;

const CheckboxInput = styled.input`
  margin-right: 0.5rem;
`;

const CopyButton = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ThemeCustomizer: React.FC = () => {
  const { theme: initialTheme } = useEloise();
  const [theme, setTheme] = useState(initialTheme);

  // Helper function to handle nested state updates
  const updateNestedTheme = (path: string[], value: any) => {
    setTheme((prevTheme) => {
      const newTheme = { ...prevTheme };
      let current: any = newTheme;
      for (let i = 0; i < path.length - 1; i++) {
        if (!current[path[i]]) current[path[i]] = {};
        current = current[path[i]];
      }
      current[path[path.length - 1]] = value;
      return newTheme;
    });
  };

  const handleColorChange = (index: number, newColor: string) => {
    const updatedColors = [...theme.colors];
    updatedColors[index] = newColor as Color;
    setTheme({ ...theme, colors: updatedColors });
  };

  const handleAddColor = () => {
    setTheme({ ...theme, colors: [...theme.colors, "hsla(0, 0%, 50%, 1)"] });
  };

  const handleRemoveColor = (index: number) => {
    const updatedColors = theme.colors.filter((_, idx) => idx !== index);
    setTheme({ ...theme, colors: updatedColors });
  };

  const handleInputChange = (field: string, value: any) => {
    setTheme({ ...theme, [field]: value });
  };

  const handleNestedInputChange = (fieldPath: string[], value: any) => {
    updateNestedTheme(fieldPath, value);
  };

  const handleCopy = () => {
    const themeString = `export const themeConfig: Theme = ${JSON.stringify(
      theme,
      null,
      2
    )};`;
    navigator.clipboard
      .writeText(themeString)
      .then(() => {
        alert("Theme config copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const Contact: React.FC = () => {
    const [first, setFirst] = useState<string>();
    const [last, setLast] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [phone, setPhone] = useState<string>();
    const [message, setMessage] = useState<string>("");

    const { logic } = useEloise();

    const validate = () => {
      if (
        ![first, last, email, phone].includes(undefined) &&
        message.length > 0
      ) {
        return true;
      }
      return false;
    };

    const handleSubmit = async () => {
      if (validate()) {
        //submit logic
        const ots = {
          first,
          last,
          email,
          phone,
          message,
        };

        let response = await logic.fb.docs.setBaseDoc(
          "/mainSite/contactForm",
          ots
        );
        if (response) {
          alert("message received");
          setFirst("");
          setLast("");
          setEmail("");
          setMessage("");
          setPhone("");
        }
      } else {
        alert("Please complete form to submit");
      }
    };

    return (
      <Container>
        <Row>
          <Heading
            size={4}
            style={theme.heading}>
            Contact Us
          </Heading>
        </Row>
        <Row>
          <Col
            lg={8}
            className="mx-auto"
            style={{ border: theme.border, borderRadius: theme.borderRadius }}>
            <Row className="my-3 mt-5 mx-auto">
              <Col
                lg={5}
                className="mx-auto">
                <Input
                  label="First Name"
                  state={first}
                  setState={setFirst}
                  extLabel
                  style={{ borderRadius: theme.borderRadius }}
                />
              </Col>
              <Col
                lg={5}
                className="mx-auto">
                <Input
                  label="Last Name"
                  state={last}
                  setState={setLast}
                  extLabel
                  style={{ borderRadius: theme.borderRadius }}
                />
              </Col>
            </Row>
            <Row className="my-3 mx-auto">
              <Col
                lg={5}
                className="mx-auto">
                <Input
                  label="Email"
                  state={email}
                  setState={setEmail}
                  extLabel
                  style={{ borderRadius: theme.borderRadius }}
                />
              </Col>
              <Col
                lg={5}
                className="mx-auto">
                <Input
                  label="Phone"
                  state={phone}
                  setState={setPhone}
                  extLabel
                  style={{ borderRadius: theme.borderRadius }}
                />
              </Col>
            </Row>
            <Row className="my-3 mx-auto">
              <Col
                lg={11}
                className="mx-auto">
                <LargeTextInput
                  state={message}
                  setState={setMessage}
                  style={{ borderRadius: theme.borderRadius }}
                  placeholder="Type your message here"></LargeTextInput>
              </Col>
            </Row>
            <Row className="my-3 ">
              <Col
                lg={5}
                className="mx-auto">
                <Button
                  style={{ borderRadius: theme.borderRadius }}
                  color={validate() ? theme.colors[0] : theme.colors[1]}
                  onClick={handleSubmit}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <Container style={{ marginTop: "10%" }}>
      <Row>
        {/* Left Column: Display components using the current theme */}
        <Col lg={6}>
          <Contact />
        </Col>

        <Col lg={6}>
          {/* Right Column: Theme customization controls */}

          <h1>Customize Theme</h1>

          {/* Colors */}
          <Section>
            <h2>Colors</h2>
            <ColorList>
              {theme.colors.map((color, index) => (
                <div key={index}>
                  <ColorSwatch
                    color={color}
                    onChange={(newColor: string) =>
                      handleColorChange(index, newColor)
                    }
                  />
                  <button onClick={() => handleRemoveColor(index)}>
                    Remove
                  </button>
                </div>
              ))}
              <button onClick={handleAddColor}>Add Color</button>
            </ColorList>
          </Section>

          {/* White Color */}
          <Section>
            <h2>White Color</h2>
            <ColorSwatch
              color={theme.white}
              onChange={(newColor: string) =>
                handleInputChange("white", newColor as HSLAColor)
              }
            />
          </Section>

          {/* Typography */}
          <Section>
            <h2>Typography</h2>
            <InputGroup>
              <Label>Font</Label>
              <TextInput
                type="text"
                value={theme.font}
                onChange={(e) => handleInputChange("font", e.target.value)}
              />
            </InputGroup>

            <InputGroup>
              <Label>Font Size</Label>
              <TextInput
                type="text"
                value={theme.fontSize}
                onChange={(e) =>
                  handleInputChange("fontSize", e.target.value as FontSize)
                }
              />
            </InputGroup>
          </Section>

          {/* Borders and Radius */}
          <Section>
            <h2>Borders</h2>
            <InputGroup>
              <Label>Border</Label>
              <TextInput
                type="text"
                value={theme.border}
                onChange={(e) => handleInputChange("border", e.target.value)}
              />
            </InputGroup>

            <InputGroup>
              <Label>Border Radius</Label>
              <TextInput
                type="text"
                value={theme.borderRadius}
                onChange={(e) =>
                  handleInputChange(
                    "borderRadius",
                    e.target.value as BorderRadius
                  )
                }
              />
            </InputGroup>
          </Section>

          {/* Mode */}
          <Section>
            <h2>Mode</h2>
            <SelectInput
              value={theme.mode}
              onChange={(e) => handleInputChange("mode", e.target.value)}>
              <option value="light">Light</option>
              <option value="dark">Dark</option>
              <option value="auto">Auto</option>
            </SelectInput>
          </Section>

          {/* Heading */}
          <Section>
            <h2>Heading</h2>
            <InputGroup>
              <Label>Heading Color</Label>
              <TextInput
                type="text"
                value={theme.heading?.color || ""}
                onChange={(e) =>
                  handleNestedInputChange(["heading", "color"], e.target.value)
                }
              />
            </InputGroup>
          </Section>

          {/* Input */}
          <Section>
            <h2>Input</h2>
            <InputGroup>
              <CheckboxInput
                type="checkbox"
                checked={theme.input?.border || false}
                onChange={(e) =>
                  handleNestedInputChange(["input", "border"], e.target.checked)
                }
              />
              <Label>Enable Border</Label>
            </InputGroup>

            <InputGroup>
              <CheckboxInput
                type="checkbox"
                checked={theme.input?.rounded || false}
                onChange={(e) =>
                  handleNestedInputChange(
                    ["input", "rounded"],
                    e.target.checked
                  )
                }
              />
              <Label>Enable Rounded Corners</Label>
            </InputGroup>

            <InputGroup>
              <CheckboxInput
                type="checkbox"
                checked={theme.input?.extLabel || false}
                onChange={(e) =>
                  handleNestedInputChange(
                    ["input", "extLabel"],
                    e.target.checked
                  )
                }
              />
              <Label>Use External Label</Label>
            </InputGroup>
          </Section>

          {/* Header Options */}
          <Section>
            <h2>Header Options</h2>
            <InputGroup>
              <CheckboxInput
                type="checkbox"
                checked={theme.noHeaderLogo || false}
                onChange={(e) =>
                  handleInputChange("noHeaderLogo", e.target.checked)
                }
              />
              <Label>Disable Header Logo</Label>
            </InputGroup>

            <InputGroup>
              <CheckboxInput
                type="checkbox"
                checked={theme.noHeaderText || false}
                onChange={(e) =>
                  handleInputChange("noHeaderText", e.target.checked)
                }
              />
              <Label>Disable Header Text</Label>
            </InputGroup>
          </Section>

          {/* Header Style */}
          <Section>
            <h2>Header Style</h2>
            <InputGroup>
              <Label>Header Style (JSON)</Label>
              <TextInput
                type="text"
                value={JSON.stringify(theme.headerStyle || {})}
                onChange={(e) => {
                  try {
                    const value = JSON.parse(e.target.value);
                    handleInputChange("headerStyle", value);
                  } catch (err) {
                    console.error("Invalid JSON");
                  }
                }}
              />
            </InputGroup>
          </Section>

          {/* Current Theme Config */}
          <Section>
            <h2>Current Theme Config</h2>
            <pre>{`export const themeConfig: Theme = ${JSON.stringify(
              theme,
              null,
              2
            )};`}</pre>
            <CopyButton onClick={handleCopy}>Copy to Clipboard</CopyButton>
          </Section>
        </Col>
      </Row>
    </Container>
  );
};

export default ThemeCustomizer;
