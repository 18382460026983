import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";
import { GlowingRedOutline } from "../../widgets";
import { Locations } from "../locations";

export const AdminLocations: React.FC = () => {
  const { theme, logic } = useEloise();

  const [locations, setLocations] = useState<Array<any>>([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<any>({
    name: "",
    address: "",
    phone: "",
    lat: "",
    lng: "",
  });

  // Fetch locations on component load
  logic.hooks.useAsyncEffect(async () => {
    const fetchCollections = await logic.fb.docs.getBaseCollection(
      "siteData/wampum/locations"
    );
    setLocations(
      fetchCollections.map((item) => ({
        id: item.id,
        ...item.data,
      }))
    );
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCurrentLocation((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleSaveLocation = async () => {
    const path = `siteData/wampum/locations/${currentLocation.name}`;

    if (!currentLocation.lat || !currentLocation.lng) {
      alert("Latitude and Longitude are required.");
      return;
    }

    // Use setBaseDoc to either create or update
    await logic.fb.docs.setBaseDoc(path, currentLocation);

    // Refresh locations and close modal
    const fetchCollections = await logic.fb.docs.getBaseCollection(
      "siteData/wampum/locations"
    );
    setLocations(
      fetchCollections.map((item) => ({
        id: item.id,
        ...item.data,
      }))
    );
    setShowModal(false);
    setCurrentLocation({ name: "", address: "", phone: "", lat: "", lng: "" });
  };

  const handleDeleteLocation = async (id: string) => {
    if (!window.confirm("Are you sure you want to delete this location?"))
      return;

    try {
      await logic.fb.docs.deleteBaseDoc(`siteData/wampum/locations/${id}`);
      setLocations((prev) => prev.filter((location) => location.id !== id));
    } catch (error) {
      console.error("Error deleting location:", error);
    }
  };

  const handleAddLocation = () => {
    setCurrentLocation({ name: "", address: "", phone: "", lat: "", lng: "" });
    setIsEditMode(false);
    setShowModal(true);
  };

  const handleEditLocation = (location: any) => {
    setCurrentLocation(location);
    setIsEditMode(true);
    setShowModal(true);
  };

  return (
    <Container
      fluid
      className="largeMargin">
      <Row className="my-4">
        <GlowingRedOutline>
          <Locations />
        </GlowingRedOutline>
      </Row>
      <Row className="my-5">
        <Col
          lg={6}
          className="mx-auto"
          style={{ borderBottom: "2px solid black" }}>
          <Heading size={4}>Locations</Heading>
        </Col>
      </Row>

      <Row className="my-5">
        <Col
          lg={10}
          className="mx-auto">
          <Row>
            {locations.map((location, index) => (
              <Col
                lg={3}
                key={index}
                className="location-info">
                <h6
                  style={{ color: theme.colors[1] }}
                  className="mb-3">
                  {location.name.replace(/_/g, "/")}
                </h6>
                <p>{location.address}</p>
                <p>
                  Phone:{" "}
                  <a
                    style={{ color: "black" }}
                    href={`tel:${location.phone}`}>
                    {location.phone}
                  </a>
                </p>
                <p>Lat: {location.lat}</p>
                <p>Lng: {location.lng}</p>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => handleEditLocation(location)}
                  className="me-2">
                  Edit
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDeleteLocation(location.id)}>
                  Delete
                </Button>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row className="my-5">
        <Col className="text-center">
          <Button
            variant="primary"
            onClick={handleAddLocation}>
            Add New Location
          </Button>
        </Col>
      </Row>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditMode ? "Edit Location" : "Add New Location"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label
              htmlFor="name"
              className="form-label">
              Name
            </label>
            <input
              id="name"
              className="form-control"
              type="text"
              name="name"
              value={currentLocation.name}
              onChange={handleInputChange}
              disabled={isEditMode}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="address"
              className="form-label">
              Address
            </label>
            <input
              id="address"
              className="form-control"
              type="text"
              name="address"
              value={currentLocation.address}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="lat"
              className="form-label">
              Latitude
            </label>
            <input
              id="lat"
              className="form-control"
              type="number"
              step="any"
              name="lat"
              value={currentLocation.lat}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="lng"
              className="form-label">
              Longitude
            </label>
            <input
              id="lng"
              className="form-control"
              type="number"
              step="any"
              name="lng"
              value={currentLocation.lng}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="phone"
              className="form-label">
              Phone
            </label>
            <input
              id="phone"
              className="form-control"
              type="text"
              name="phone"
              value={currentLocation.phone}
              onChange={handleInputChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveLocation}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
