import { SiteConfig } from "typed-component-gallery";
import annLogo from "../assets/annLogo.png";
import logo from "../assets/ogLogo.png";
import { Pages } from "./pages";

export const site: SiteConfig = {
  id: "wampum",
  name: "Wampum Hardware Co.",
  logo: annLogo,
  inverseLogo: logo,
  api: "http://localhost:5001",
  defaultMode: "user",
  headerTrans: false,
  noAuth: true,
  peopleConfig: [{ title: "admin", icon: "stest" }],
  sideWidget: [],
  logoWidth: 200,
  pages: Pages,
  eloiseConfig: {
    endPoint: "http://127.0.0.1:5001/eloiselife-c5cf6/us-central1/musicGpt",
    chatLog: "vibez/chat",
    initMessage:
      "Hey there, Describe the music you are seeking and I will see what I can do. ",
  },
  hostingSite: "vibez",
};
