import React, { useState } from "react";
import {
  Accordion,
  Button as BootstrapButton,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { useEloise } from "typed-component-gallery";

const InformationAccordion: React.FC<{ edit?: boolean }> = ({ edit }) => {
  const { logic } = useEloise();
  const [safetyAcc, setSafetyAcc] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState(false);

  // Fetch accordion data
  logic.hooks.useAsyncEffect(async () => {
    const fetchCollections = await logic.fb.docs.getBaseCollection(
      "siteData/wampum/safteyAccordian"
    );

    setSafetyAcc(
      fetchCollections.map((item) => ({
        ...item.data,
        id: item.id,
      }))
    );
  }, []);

  // Save changes to Firebase
  const saveChanges = async () => {
    try {
      for (const item of safetyAcc) {
        await logic.fb.docs.setBaseDoc(
          `siteData/wampum/safteyAccordian/${item.id}`,
          item
        );
      }
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  // --- ITEM HANDLERS ---
  const handleTitleChange = (index: number, value: string) => {
    const updatedAcc = [...safetyAcc];
    updatedAcc[index].title = value;
    setSafetyAcc(updatedAcc);
  };

  const handleTextChange = (index: number, value: string) => {
    const updatedAcc = [...safetyAcc];
    updatedAcc[index].text = value;
    setSafetyAcc(updatedAcc);
  };

  // --- SUBITEM HANDLERS ---
  const handleSubItemChange = (
    itemIndex: number,
    subIndex: number,
    value: string
  ) => {
    const updatedAcc = [...safetyAcc];
    if (!updatedAcc[itemIndex].list) {
      updatedAcc[itemIndex].list = [];
    }
    updatedAcc[itemIndex].list[subIndex] = value;
    setSafetyAcc(updatedAcc);
  };

  const handleAddSubItem = (itemIndex: number) => {
    const updatedAcc = [...safetyAcc];
    if (!updatedAcc[itemIndex].list) {
      updatedAcc[itemIndex].list = [];
    }
    updatedAcc[itemIndex].list.push("New SubItem");
    setSafetyAcc(updatedAcc);
  };

  const handleRemoveSubItem = (itemIndex: number, subIndex: number) => {
    const updatedAcc = [...safetyAcc];
    if (updatedAcc[itemIndex].list) {
      updatedAcc[itemIndex].list = updatedAcc[itemIndex].list.filter(
        (_: any, i: number) => i !== subIndex
      );
    }
    setSafetyAcc(updatedAcc);
  };

  // --- ITEM ADD/REMOVE ---
  const addNewItem = () => {
    const newItem = {
      id: `${Date.now()}`,
      title: "New Item",
      text: "",
      list: [] as string[],
    };
    setSafetyAcc([...safetyAcc, newItem]);
  };

  const removeItem = (index: number) => {
    const updatedAcc = safetyAcc.filter((_, i) => i !== index);
    setSafetyAcc(updatedAcc);
  };

  return (
    <Container>
      <Row className="my-5">
        <Col lg={12}>
          <Accordion
            defaultActiveKey={["0", "1", "2"]}
            alwaysOpen>
            {safetyAcc.map((item: any, index: number) => (
              <Accordion.Item
                eventKey={index.toString()}
                key={item.id}>
                <Accordion.Header>
                  {isEditing && edit ? (
                    <Form.Control
                      type="text"
                      value={item.title}
                      onChange={(e) => handleTitleChange(index, e.target.value)}
                    />
                  ) : (
                    item.title
                  )}
                </Accordion.Header>
                <Accordion.Body>
                  {isEditing && edit ? (
                    <Form.Control
                      as="textarea"
                      value={item.text}
                      onChange={(e) => handleTextChange(index, e.target.value)}
                    />
                  ) : (
                    item.text
                  )}

                  {/* Render subItems (if any) */}
                  {item.list && item.list.length > 0 && (
                    <ul className="mt-3">
                      {item.list.map((subItem: string, subIndex: number) => (
                        <li key={subIndex}>
                          {isEditing && edit ? (
                            <div className="d-flex align-items-center">
                              <Form.Control
                                type="text"
                                value={subItem}
                                onChange={(e) =>
                                  handleSubItemChange(
                                    index,
                                    subIndex,
                                    e.target.value
                                  )
                                }
                              />
                              <BootstrapButton
                                variant="danger"
                                size="sm"
                                onClick={() =>
                                  handleRemoveSubItem(index, subIndex)
                                }
                                className="ms-2">
                                Remove
                              </BootstrapButton>
                            </div>
                          ) : (
                            subItem
                          )}
                        </li>
                      ))}
                    </ul>
                  )}

                  {/* Button to add a new subItem */}
                  {isEditing && edit && (
                    <BootstrapButton
                      variant="success"
                      size="sm"
                      onClick={() => handleAddSubItem(index)}
                      className="mt-2">
                      Add SubItem
                    </BootstrapButton>
                  )}

                  {/* Button to remove the entire item */}
                  {isEditing && edit && (
                    <BootstrapButton
                      variant="danger"
                      size="sm"
                      onClick={() => removeItem(index)}
                      className="mt-2 ms-2">
                      Remove Item
                    </BootstrapButton>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>

      {/* Edit Controls */}
      {edit && (
        <Row>
          <Col className="text-center">
            {isEditing ? (
              <>
                <BootstrapButton
                  variant="primary"
                  onClick={saveChanges}
                  className="me-2">
                  Save Changes
                </BootstrapButton>
                <BootstrapButton
                  variant="secondary"
                  onClick={() => setIsEditing(false)}>
                  Cancel
                </BootstrapButton>
              </>
            ) : (
              <BootstrapButton
                variant="primary"
                onClick={() => setIsEditing(true)}>
                Edit
              </BootstrapButton>
            )}
            {isEditing && edit && (
              <BootstrapButton
                variant="success"
                onClick={addNewItem}
                className="ms-2">
                Add Item
              </BootstrapButton>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default InformationAccordion;
