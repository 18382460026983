import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";
import "../assets/vid.css";
import { DBButton } from "./DbButton";

export const ContactInfo: React.FC<{ edit?: boolean }> = ({ edit }) => {
  const { theme, logic } = useEloise();
  const [contactInfo, setContactInfo] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchContactInfo = async () => {
      const data = await logic.fb.docs.getBaseDoc(
        "siteData/wampum/main/contactInfo"
      );
      setContactInfo(data?.data || {});
    };
    fetchContactInfo();
  }, [logic]);

  const saveChanges = async () => {
    try {
      await logic.fb.docs.setBaseDoc(
        "siteData/wampum/main/contactInfo",
        contactInfo
      );
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleFieldChange = (
    columnIndex: number,
    contentIndex: number,
    key: string,
    value: string
  ) => {
    setContactInfo((prev: any) => {
      const updatedColumns = [...prev.columns];
      updatedColumns[columnIndex].content[contentIndex][key] = value;
      return { ...prev, columns: updatedColumns };
    });
  };

  if (!contactInfo) {
    return <div>Loading...</div>;
  }

  return (
    <Container
      fluid
      style={{ position: "relative", display: "inline-block", width: "100%" }}>
      {edit && (
        <div
          style={{
            position: "absolute",
            top: "-10px",
            right: "-5px",
            cursor: "pointer",
            zIndex: 10,
          }}
          onClick={() => {
            setIsEditing(!isEditing);
          }}>
          <Icon
            path={mdiPencil}
            size={1}
          />
        </div>
      )}
      <Row>
        {/* Left Column: Contact Information */}
        <Col
          lg={12}
          className="mx-auto">
          <Row className="mb-3">
            <Col
              lg={5}
              xs={10}
              className="mx-auto"
              style={{ borderBottom: theme.border }}>
              <Heading size={4}>
                {isEditing && edit ? (
                  <Form.Control
                    type="text"
                    value={contactInfo.heading}
                    onChange={(e) =>
                      setContactInfo((prev: any) => ({
                        ...prev,
                        heading: e.target.value,
                      }))
                    }
                  />
                ) : (
                  contactInfo.heading
                )}
              </Heading>
            </Col>
          </Row>

          <Col
            xs={12}
            className="mx-auto p-5 contact"
            style={{
              backgroundColor: theme.colors[0],
              color: "white",
              borderRadius: "5px",
            }}>
            <Row>
              {contactInfo.columns.map((column: any, columnIndex: number) => (
                <Col
                  key={columnIndex}
                  md={6}
                  lg={4}
                  className={`mx-auto ${
                    columnIndex === 0
                      ? "smallNoBorder"
                      : columnIndex === 2
                      ? "smallNoBorder"
                      : ""
                  }`}
                  style={
                    columnIndex === 0
                      ? { borderRight: "2px solid " + theme.colors[1] }
                      : columnIndex === 2
                      ? { borderLeft: "2px solid " + theme.colors[1] }
                      : {}
                  }>
                  <h5>
                    {isEditing && edit ? (
                      <Form.Control
                        type="text"
                        value={column.title}
                        onChange={(e) =>
                          setContactInfo((prev: any) => {
                            const updatedColumns = [...prev.columns];
                            updatedColumns[columnIndex].title = e.target.value;
                            return { ...prev, columns: updatedColumns };
                          })
                        }
                      />
                    ) : (
                      column.title
                    )}
                  </h5>
                  {column.content.map((item: any, contentIndex: number) => {
                    if (item.type === "text") {
                      return (
                        <p key={contentIndex}>
                          {isEditing && edit ? (
                            <Form.Control
                              as="textarea"
                              value={item.value}
                              onChange={(e) =>
                                handleFieldChange(
                                  columnIndex,
                                  contentIndex,
                                  "value",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            item.value
                              .split("\n")
                              .map((line: string, i: number) => (
                                <React.Fragment key={i}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))
                          )}
                        </p>
                      );
                    }
                    if (item.type === "title") {
                      return (
                        <h5 key={contentIndex}>
                          {isEditing && edit ? (
                            <Form.Control
                              type="text"
                              value={item.value}
                              onChange={(e) =>
                                handleFieldChange(
                                  columnIndex,
                                  contentIndex,
                                  "value",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            item.value
                          )}
                        </h5>
                      );
                    }
                    if (item.type === "link") {
                      return (
                        <p key={contentIndex}>
                          {item.label && `${item.label}: `}
                          {isEditing && edit ? (
                            <Form.Control
                              type="text"
                              value={item.value}
                              onChange={(e) =>
                                handleFieldChange(
                                  columnIndex,
                                  contentIndex,
                                  "value",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <a
                              style={{
                                color: "white",
                                marginLeft: item.label ? "6px" : "0px",
                                textDecorationSkipInk: "none",
                              }}
                              href={item.href}>
                              {item.value}
                            </a>
                          )}
                        </p>
                      );
                    }
                    return null;
                  })}
                </Col>
              ))}
            </Row>

            {contactInfo.cta && (
              <Row className="my-3">
                <Col
                  lg={6}
                  className="mx-auto text-center">
                  {isEditing && edit ? (
                    <Form.Control
                      type="text"
                      value={contactInfo.cta.text}
                      onChange={(e) =>
                        setContactInfo((prev: any) => ({
                          ...prev,
                          cta: { ...prev.cta, text: e.target.value },
                        }))
                      }
                    />
                  ) : (
                    <DBButton
                      path="siteData/wampum/widgets/contactcta"
                      init={{
                        text: "Join Our Team",
                        link: "https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=327C0ECE7C6877837B56F5A004534DF2&fromClientSide=true",
                      }}
                      style={{
                        width: "80%",
                        backgroundColor: theme.colors[1],
                        border: "none",
                        borderRadius: 0,
                      }}
                    />
                  )}
                </Col>
              </Row>
            )}
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactInfo;
