import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { Button as BootstrapButton, Form, Modal } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";

export interface DBButtonData {
  text: string;
  link?: string;
}

export const DBButton: React.FC<{
  path: string;
  init?: DBButtonData;
  style?: { [key: string]: any };
  edit?: boolean;
}> = ({ path, init, style, edit }) => {
  const { logic, theme } = useEloise();
  const [data, setData] = useState<DBButtonData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [tempData, setTempData] = useState<DBButtonData | null>(null);

  logic.hooks.useAsyncEffect(async () => {
    try {
      if (init) {
        await logic.fb.docs.setBaseDoc(path, init);
        setData(init);
      } else {
        const fetchData = await logic.fb.docs.getBaseDoc(path);
        setData(fetchData || {});
      }
    } catch (err) {
      setError("Failed to load button data.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSave = async () => {
    if (tempData) {
      try {
        await logic.fb.docs.setBaseDoc(path, tempData);
        setData(tempData);
        setShowModal(false);
      } catch (err) {
        console.error("Failed to save changes:", err);
      }
    }
  };

  const handleOpenModal = () => {
    setTempData(data); // Initialize temporary data with current data
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setTempData(null); // Reset temporary data
    setShowModal(false);
  };

  if (loading) return <span>Loading...</span>;
  if (error) return <span>{error}</span>;

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {/* Edit Icon */}
      {edit && (
        <div
          style={{
            position: "absolute",
            top: "-20px",
            right: "-25px",
            cursor: "pointer",
            zIndex: 10,
          }}
          onClick={handleOpenModal}>
          <Icon
            path={mdiPencil}
            size={1}
          />
        </div>
      )}

      {/* Button */}
      <a
        href={data?.link || "#"}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-primary"
        style={{
          ...style,
          backgroundColor: theme.colors[1],
          border: "none",
          borderRadius: 0,
        }}>
        {data?.text || "Button"}
      </a>

      {/* Modal for Editing */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Button</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Button Text</Form.Label>
              <Form.Control
                type="text"
                value={tempData?.text || ""}
                onChange={(e) =>
                  setTempData((prev) => ({
                    ...prev!,
                    text: e.target.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Button Link</Form.Label>
              <Form.Control
                type="url"
                value={tempData?.link || ""}
                onChange={(e) =>
                  setTempData((prev) => ({
                    ...prev!,
                    link: e.target.value,
                  }))
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton
            variant="secondary"
            onClick={handleCloseModal}>
            Cancel
          </BootstrapButton>
          <BootstrapButton
            variant="primary"
            onClick={handleSave}>
            Save Changes
          </BootstrapButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
