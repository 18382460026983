import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { Heading, useEloise } from "typed-component-gallery";
import blastVidNew from "../../assets/vidBlast.mp4";
import "../assets/vid.css";
import ServicesList from "../widgets/servicesList";
import { DBImage } from "./DbImage";
import { DBVideo } from "./DbVid";

const blastVids = [blastVidNew];

export const Services: React.FC = () => {
  const { theme, logic } = useEloise();
  const [user] = useAuthState(logic.fb.auth);

  return (
    <Container
      fluid
      className="largeMargin">
      <Row className="my-3">
        <Col
          style={{
            borderRight: `1px solid ${theme.colors[1]}`,
            borderBottom: `1px solid ${theme.colors[1]}`,
          }}
          lg={7}
          xs={10}
          className="mx-auto triangle-corner-col verticalSmall">
          <Row className="mb-3">
            <Col
              lg={5}
              className="mx-auto"
              style={{ borderBottom: theme.border }}>
              <Heading size={2}>Services We Offer</Heading>
            </Col>
          </Row>
          <ServicesList />
        </Col>
        <Col
          lg={5}
          className="mx-auto">
          <Row className="my-3">
            <Col
              className="smallAuto text-center mx-auto"
              lg={9}
              xs={10}>
              <DBImage
                edit={user ? true : false}
                style={{ width: "100%" }}
                path={`siteData/wampum/servicesImages/servicesImage1`}
                init={{
                  image:
                    "https://firebasestorage.googleapis.com/v0/b/eloiselife-c5cf6.appspot.com/o/wampum%2FMain%2Fimages%2FbulkTrucksM.png?alt=media&token=9b20438e-e555-441b-a342-d2833b358a95",
                  altText: `Trucks Image`,
                }}
              />
            </Col>
          </Row>

          <Row className="mt-5">
            <Col
              className="smallAuto text-center"
              lg={6}
              xs={10}>
              <DBImage
                edit={user ? true : false}
                style={{ width: "100%" }}
                path={`siteData/wampum/seviceImages/servicesImage2`}
                init={{
                  image:
                    "https://firebasestorage.googleapis.com/v0/b/eloiselife-c5cf6.appspot.com/o/wampum%2FMain%2Fimages%2FnewDrill.jpg?alt=media&token=243d095e-25f0-4663-a7d8-481e83356456",
                  altText: `Drill Image`,
                }}
              />
            </Col>
            <Col
              className="smallAuto text-center"
              lg={6}
              xs={10}>
              <DBImage
                edit={user ? true : false}
                style={{ width: "100%" }}
                path={`siteData/wampum/seviceImages/servicesImage3`}
                init={{
                  image:
                    "https://firebasestorage.googleapis.com/v0/b/eloiselife-c5cf6.appspot.com/o/wampum%2FMain%2Fimages%2Fhanging.jpg?alt=media&token=e962a4cd-7936-4b0f-8389-3c144e09855d",
                  altText: `Hanging`,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-4">
        {blastVids.map((vid) => (
          <Col
            lg={11}
            xs={11}
            className="mx-auto">
            <DBVideo
              path="siteData/wampum/seviceImages/servicesVideo"
              edit={user ? true : false}
              init={{
                vid: "https://firebasestorage.googleapis.com/v0/b/eloiselife-c5cf6.appspot.com/o/wampum%2FMain%2Fimages%2FvidBlast.mp4?alt=media&token=65ebdf2c-a005-470f-837e-28f500319f6a",
                altText: " Blasting Video ",
              }}
              className="nonGalleryImage"
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
