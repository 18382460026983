import { useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { Button, useEloise } from "typed-component-gallery";
import { v4 as uuidv4 } from "uuid"; // To generate unique IDs for new events
import "../assets/vid.css";

export const Timeline: React.FC<{ edit?: boolean }> = ({ edit }) => {
  const [shadow, setShadow] = useState<string>("");
  const timelineRef = useRef<HTMLDivElement>(null);
  const [timeLine, setTimeline] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [newEvent, setNewEvent] = useState({ year: "", description: "" }); // State for new event
  const [bulkEdit, setBulkEdit] = useState(false); // State for bulk edit mode

  const { theme, logic } = useEloise();

  // Fetch timeline events on component load
  logic.hooks.useAsyncEffect(async () => {
    const fetchCollections = await logic.fb.docs.getBaseCollection(
      "siteData/wampum/timeline"
    );
    setTimeline(
      fetchCollections.map((item) => ({
        id: item.id,
        ...item.data,
      }))
    );
  }, []);

  const saveChanges = async () => {
    try {
      for (const event of timeLine) {
        await logic.fb.docs.setBaseDoc(
          `siteData/wampum/timeline/${event.id}`,
          event
        );
      }
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleFieldChange = (index: number, field: string, value: string) => {
    setTimeline((prev) =>
      prev.map((item, i) =>
        i === index
          ? {
              ...item,
              [field]: value,
            }
          : item
      )
    );
  };

  const handleDeleteEvent = async (id: string) => {
    if (!window.confirm("Are you sure you want to delete this event?")) {
      return;
    }
    try {
      await logic.fb.docs.deleteBaseDoc(`siteData/wampum/timeline/${id}`);
      setTimeline((prev) => prev.filter((event) => event.id !== id));
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const handleScroll = () => {
    const element = timelineRef.current;
    if (element) {
      const atTop = element.scrollTop === 0;
      const atBottom =
        Math.abs(
          element.scrollHeight - element.scrollTop - element.clientHeight
        ) < 1;

      if (atTop) {
        setShadow(atBottom ? "" : "shadow-bottom");
      } else if (atBottom) {
        setShadow("shadow-top");
      } else {
        setShadow("shadow-both");
      }
    }
  };

  useEffect(() => {
    const element = timelineRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
      handleScroll();
      return () => element.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const addNewEvent = async () => {
    if (!newEvent.year || !newEvent.description) {
      alert("Please fill in both the year and description.");
      return;
    }

    const newEventWithId = { ...newEvent, id: uuidv4() };
    try {
      await logic.fb.docs.setBaseDoc(
        `siteData/wampum/timeline/${newEventWithId.id}`,
        newEventWithId
      );
      setTimeline((prev) => [...prev, newEventWithId]); // Add new event to the timeline
      setNewEvent({ year: "", description: "" }); // Reset the form
    } catch (error) {
      console.error("Error adding new event:", error);
    }
  };

  return (
    <Container fluid>
      {bulkEdit ? (
        <Row>
          <Col lg={12}>
            <Table
              striped
              bordered
              hover
              responsive>
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {timeLine.map((event, index) => (
                  <tr key={event.id}>
                    <td>
                      <Form.Control
                        type="text"
                        value={event.year}
                        onChange={(e) =>
                          handleFieldChange(index, "year", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        value={event.description}
                        onChange={(e) =>
                          handleFieldChange(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <Button
                        color={1}
                        onClick={() => handleDeleteEvent(event.id)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="text-center">
              <Button
                onClick={saveChanges}
                className="me-2">
                Save Changes
              </Button>
              <Button onClick={() => setBulkEdit(false)}>Cancel</Button>
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          {/* Timeline View */}
          <Col lg={edit ? 8 : 12}>
            <div
              className={`outer mx-auto noScrollBar timeline ${shadow}`}
              ref={timelineRef}>
              {timeLine
                .sort((a, b) => {
                  const yearA =
                    a.year === "Present" ? Number.MAX_SAFE_INTEGER : a.year;
                  const yearB =
                    b.year === "Present" ? Number.MAX_SAFE_INTEGER : b.year;
                  return yearA - yearB;
                })
                .map((event, index) => (
                  <div
                    key={event.id}
                    className="card">
                    <div className="info">
                      {isEditing && edit ? (
                        <>
                          <Form.Group className="mb-2">
                            <Form.Label>Year</Form.Label>
                            <Form.Control
                              type="text"
                              value={event.year}
                              onChange={(e) =>
                                handleFieldChange(index, "year", e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={event.description}
                              onChange={(e) =>
                                handleFieldChange(
                                  index,
                                  "description",
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                          <Button
                            color={1}
                            onClick={() => handleDeleteEvent(event.id)}>
                            Delete
                          </Button>
                        </>
                      ) : (
                        <>
                          <h3 className="title">{event.year.toString()}</h3>
                          <p>{event.description}</p>
                        </>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </Col>

          {/* Edit Controls */}
          {edit && (
            <Col lg={4}>
              <div className="text-center my-3">
                {isEditing ? (
                  <>
                    <Button
                      onClick={saveChanges}
                      className="me-2">
                      Save Changes
                    </Button>
                    <Button
                      color={1}
                      onClick={() => setIsEditing(false)}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => setBulkEdit(!bulkEdit)}
                      className="my-2">
                      {bulkEdit ? "Exit Bulk Edit" : "Bulk Edit"}
                    </Button>

                    <Button onClick={() => setIsEditing(true)}>Edit</Button>
                  </>
                )}
              </div>
              <div className="new-event-form">
                <Form
                  className="p-3"
                  style={{
                    backgroundColor: theme.colors[0],
                    color: "white",
                  }}>
                  <Form.Group className="mb-2">
                    <Form.Label>Year</Form.Label>
                    <Form.Control
                      type="text"
                      value={newEvent.year}
                      onChange={(e) =>
                        setNewEvent((prev) => ({
                          ...prev,
                          year: e.target.value,
                        }))
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={newEvent.description}
                      onChange={(e) =>
                        setNewEvent((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                    />
                  </Form.Group>
                  <Button
                    color={2}
                    onClick={addNewEvent}>
                    Add Event
                  </Button>
                </Form>
              </div>
            </Col>
          )}
        </Row>
      )}
    </Container>
  );
};
