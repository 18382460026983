import { mdiArrowDownThin } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";
import logoFull from "../../assets/logoFull.png";

import "../assets/vid.css";
import { DBButton } from "./DbButton";
import { DBHeading } from "./DBHeading";
export const Welcome: React.FC<{ edit: boolean }> = ({ edit }) => {
  const { theme, logic, siteConfig } = useEloise();

  return (
    <Row style={{ marginTop: "10vh" }}>
      <Col
        lg={5}
        xs={10}
        className="mx-auto text-center p-4">
        <img
          className="my-3"
          src={logoFull}
          style={{ width: "100%" }}></img>{" "}
        <DBHeading
          edit={edit}
          path="siteData/wampum/widgets/dashIntro"
          // init={{
          //   text: "Premium Explosives Supplier for Exceptional Drilling & Blasting Outcomes",
          // }}
          size={3}
          style={{ fontWeight: "bold" }}></DBHeading>
        <Row className="my-4">
          <Col
            lg={6}
            className="mx-auto text-center">
            <DBButton
              edit={edit}
              path="siteData/wampum/widgets/maincta"
              init={{
                text: "Join Our Team",
                link: "https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=327C0ECE7C6877837B56F5A004534DF2&fromClientSide=true",
              }}
              style={{
                width: "100%",
                backgroundColor: theme.colors[1],
                border: "none",
                borderRadius: 0,
                fontSize: "1.3rem",
              }}
            />
          </Col>
        </Row>
        <Icon
          style={{ marginTop: "16vh" }}
          path={mdiArrowDownThin}
          size={3}
        />
      </Col>
    </Row>
  );
};
