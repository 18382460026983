import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { Heading, useEloise } from "typed-component-gallery";
import { Podcast } from "../widgets/podcast";

export const Media: React.FC = () => {
  const { theme, logic } = useEloise();
  const [mediaData, setMediaData] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [edit, setEdit] = useState(false);

  const [user, loading, error] = useAuthState(logic.fb.auth);

  const staticData = {
    youtubeLinks: [
      "https://www.youtube.com/embed/jWaGptRynxw?si=PVjmyfwc6ahzVh3l",
      "https://www.youtube.com/embed/4WjhG-xLOIM?si=xRj67WSKy_ydOQE7",
    ],
    podcasts: [
      {
        label: "Podcast 1",
        show: true,
        src: "https://embed.podcasts.apple.com/us/podcast/ep11-giving-the-customer-what-they-need-with-jay-elkin/id1669925989?i=1000616031717&amp;itsct=podcast_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto",
      },
      {
        label: "Podcast 2",
        show: true,
        src: "https://embed.podcasts.apple.com/us/podcast/precision-blasting-mastering-the-art-of-explosives/id1494235724?i=1000635763367&amp;itsct=podcast_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto",
      },
    ],
  };

  useEffect(() => {
    if (user) {
      setEdit(true);
    }
  }, [user]);

  // Fetch media data or initialize with static data
  useEffect(() => {
    const fetchMediaData = async () => {
      try {
        const data = await logic.fb.docs.getBaseDoc(
          "siteData/wampum/pages/media"
        );
        if (data?.data) {
          setMediaData(data.data);
        } else {
          await logic.fb.docs.setBaseDoc(
            "siteData/wampum/pages/media",
            staticData
          );
          setMediaData(staticData);
        }
      } catch (error) {
        console.error("Error fetching media data:", error);
      }
    };
    fetchMediaData();
  }, [logic]);

  const saveChanges = async () => {
    try {
      await logic.fb.docs.setBaseDoc("siteData/wampum/pages/media", mediaData);
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving media data:", error);
    }
  };

  const handleYouTubeChange = (index: number, value: string) => {
    setMediaData((prev: any) => {
      const updatedLinks = [...prev.youtubeLinks];
      updatedLinks[index] = value;
      return { ...prev, youtubeLinks: updatedLinks };
    });
  };

  const handlePodcastChange = (updatedPodcasts: any[]) => {
    setMediaData((prev: any) => ({
      ...prev,
      podcasts: updatedPodcasts,
    }));
  };

  if (!mediaData) return <div>Loading...</div>;

  return (
    <Container
      fluid
      className="largeMargin">
      <Row className="my-4">
        <Col
          lg={5}
          className="mx-auto"
          style={{ borderBottom: "2px solid black" }}>
          <Heading size={4}>Media</Heading>
        </Col>
      </Row>

      {mediaData.youtubeLinks.map((link: string, index: number) => (
        <Row
          key={index}
          className="my-4 p-0 g-0">
          <Col
            lg={9}
            className="mx-auto text-center">
            {isEditing && edit ? (
              <Form.Control
                type="text"
                value={link}
                onChange={(e) => handleYouTubeChange(index, e.target.value)}
              />
            ) : (
              <iframe
                style={{ width: "100%", height: "500px" }}
                src={link}
                title={`YouTube video ${index + 1}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            )}
          </Col>
        </Row>
      ))}

      <Podcast
        edit={edit}
        podcasts={mediaData.podcasts}
        onPodcastsChange={handlePodcastChange}
      />

      {edit && (
        <Row>
          <Col className="text-center my-3">
            {isEditing ? (
              <>
                <Button
                  variant="primary"
                  className="me-2"
                  onClick={saveChanges}>
                  Save Changes
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setIsEditing(false)}>
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                variant="primary"
                onClick={() => setIsEditing(true)}>
                Edit
              </Button>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export const MediaPage: React.FC = () => {
  const { logic } = useEloise();
  const [user, loading] = useAuthState(logic.fb.auth);

  useEffect(() => {
    if (user) {
      window.location.href = "/admin/media";
    }
  }, [user]);

  return <Media />;
};
